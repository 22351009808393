.centre-card {
  padding: 1.8rem 2.2rem 2.2rem;
  border-radius: $border-radius;
  background-color: $white;
  border: 2px solid $warm-grey-2;
  @include vertical-margin(9, top);
  @include vertical-margin(9, bottom);

  .centre-title {
    @include vertical-margin(4, bottom);
  }

  .course-title {
    @include vertical-margin(5, top);
    @include vertical-margin(1, bottom);
  }

  .center-partner {
    font-weight: $font-weight--heavy;
  }

  .center-distance {
    font-weight: $font-weight--normal;
    display: inline-block;
    @include vertical-margin(5, bottom);
  }

  .center-info {
    @include vertical-margin(6, bottom);
  }

  hr {
    border: none;
    border-top: 2px solid $warm-grey-2;
    @include vertical-margin(2, top);
    @include vertical-margin(2, bottom);
  }
}

.centre-cards {
  &__load-more {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.centre-contacts {
  &__list-title {
    color: $blue-steel;
    @include vertical-margin(4, bottom);

    &__money-course {
      @include vertical-margin(1, bottom);
    }
  }

  &__money-course {
    @include vertical-margin(7, bottom);
  }
}

.centre-contact {
  @include vertical-margin(7, bottom);

  &__photo {
    margin-bottom: 1.2rem;
  }

  &__photo-asset {
    display: block;
    margin-top: .4rem;
  }

  &__name {
    margin-bottom: 0;
  }

  &__job-title {
    color: $blue-steel;
  }

  &__details {
    @include list-reset();
    @include vertical-margin(4, top);

    & > li {
      position: relative;
      padding-left: 3.2rem;
    }

    .centre-contact__icon {
      position: absolute;
      left: 0;
      top: .6rem;
      color: $primary-colour;
    }
  }
}

.centre-opening {
  @include vertical-margin(6, bottom);

  &__title {
    color: $blue-steel;
    margin-bottom: 0;
  }

  &__money-course {
    @include vertical-margin(3, bottom);
  }
}

.course-time {
  @include list-reset();
  @include vertical-margin(6, bottom);

  &__icon {
    color: $primary-colour;
    margin-right: 1rem;
  }
}

.button__join-course {
  margin-top: 0;
  @include vertical-margin(6, bottom);
}

//------//
// > md //
//------//

@media screen and (min-width: map-get($breakpoints, md)) {
  .centre-card {
    padding: 2.2rem 2.6rem 2.6rem;
  }

  .centre-contact {
    display: flex;

    &__photo {
      margin-bottom: 0;
    }

    &__photo-asset {
      margin-right: 2.4rem;
    }

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}