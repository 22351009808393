.site-search-form {
  display: flex;
  max-width: 256px;

  input[type=search] {
    padding-left: 1.4rem;
    margin-bottom: 0;
    border: 2px solid $link__colour;
    border-right: none;
    // border-radius: 4px 0 0 4px;
    width: calc(100% - 4.8rem);

    &::placeholder {
      color: $body__font-colour;
    }

    &:focus {
      padding: $field-input__padding--focus;
      padding-left: 1.3rem;
      border: $field-input__border--focus;
      border-right: none;
      box-shadow: $field-input__box-shadow--focus;
    }
  }

  .icon-button {
    @include button-reset();
    flex: 0 0 4.8rem;
    width: 4.8rem;
    height: 4.8rem;
    background-color: $link__colour;
    // border-radius: 0 4px 4px 0;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: $blue-mid-dark-10;
    }

    &:focus {
      color: $accessibility__text;
      background-color: $accessibility__highlight;
      outline: none;
    }

    &:active {
      color: $white;
      background-color: $blue-mid-dark-20;
    }
  }

  &--desktop {
    display: none;
  }

  &__search {
    max-width: none;
    @include vertical-margin(7, bottom);

    input[type=search] {
      padding-left: .6rem;
      border-color: $blue-steel;
      // border-radius: 0;
      width: calc(100% - 4rem);
    }

    .icon-button {
      flex: 0 0 4rem;
      width: 4rem;
      height: 4rem;
      background-color: $primary-colour;
      // border-radius: 0;

      &:hover {
        background-color: $green-access-dark-10;
      }
    }
  }
}

//------//
// > lg //
//------//

@media screen and (min-width: map-get($breakpoints, lg)) {
  .site-search-form {
    max-width: 264px;
    margin-left: auto;
    
    &--desktop {
      display: flex;
    }
    
    input[type=search] {
      width: calc(100% - 5.6rem);
    }
    
    .icon-button {
      flex: 0 0 5.6rem;
      width: 5.6rem;
      height: 5.6rem;
    }

    &__search {  
      max-width: 576px;
      margin-left: 0;
      margin-right: auto;

      input[type=search] {
        width: calc(100% - 4rem);
      }
  
      .icon-button {
        flex: 0 0 4rem;
        width: 4rem;
        height: 4rem;
      }
    }
  }
  
  .site-header__top {
    position: relative;
    z-index: 3;
    .site-search-form {
      padding-right: 1.6rem;
      border-right: 2px solid $warm-grey-2;
    }
  }
}