.hero-cta {
  position: relative;
  color: $white;
  background-color: $blue-steel;

  &__image {
    position: relative;
    padding-top: 56.25%;
    min-height: 200px;
  }

  &__image-asset {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  &__heading {
    color: $white;
    @include vertical-margin(4, bottom);
  }

  &__text {
    font-weight: $font-weight--heavy;
    @include vertical-margin(6, bottom);
  }

  &__content {
    padding-top: 2.8rem;
    padding-bottom: 3.2rem;
  }

  &__button {
    padding-bottom: 4px;

    .button {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .hero-cta {
    &__image {
      padding-top: 0;
      height: 360px;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, xl)) {
  .hero-cta {
    background-color: transparent;

    &__image {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: auto;
    }

    &__content {
      position: relative;
      background-color: $blue-steel;
      margin-top: 11rem;
      margin-bottom: 11rem;
      padding-left: 3.2rem;
      padding-right: 3.2rem;
      border-radius: $border-radius;
    }
  }
}