.stats-section {
  color: $blue-steel;

  &__heading {
    @include vertical-margin(6, bottom);
  }

  &__content-heading {
    display: flex;
    height: 100%;
    align-items: center;
  }

  &__stat-statistic {
    margin-bottom: .8rem;
  }

  .heading-stat {
    color: $primary-colour;
  }

  &__stat {
    color: $black;
    padding: 1.8rem 2.2rem 2.2rem;
    background-color: $white;
    border: 2px solid $blue-steel-tint-10;
    border-radius: $border-radius;
    height: 100%;
  }

  &__stats {
    @include vertical-margin(9, top);
  }
}

.matrix--stats-section {
  @include vertical-padding(9, top);
  @include vertical-padding(9, bottom);
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .stats-section {
    &__heading {
      display: flex;
      flex-wrap: wrap;

      .icon {
        flex: 0 0 70px;
        margin-right: 2.4rem;
      }

      h2 {
        margin-top: .6rem;
      }
    }

    &__stat-text {
      line-height: 2.8rem;
    }

    &__stat {
      padding: 1.8rem 2.6rem 2.2rem;
    }
  }
}