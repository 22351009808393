.sitewide-appeal-banner-wrapper {
  position: relative;
}

.sitewide-appeal-banner {
  color: $blue-steel;
  padding: 4px 0 12px;
  background-image: linear-gradient(-45deg, #76BE5E 25%, #46A929 25%, #46A929 50%, #76BE5E 50%, #76BE5E 75%, #46A929 75%, #46A929 100%);
  background-size: 100px 100px;

  &__text {
    font-weight: $font-weight--bold;

    &--desktop {
      display: none;
    }
  }

  .button {
    margin: 2px 0 0;
    padding: .4rem 1.6rem .4rem;
  }

  &--fixed {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 2;
    top: 0;
    transition: top .4s ease-in-out;

    &.sticky {
      position: fixed;

      &.hide {
        top: -499px;
      }
    }
  }

  &--static {
    position: static;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .sitewide-appeal-banner {
    padding: 16px 0;

    .container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .button {
      flex-shrink: 0;
      margin: 0 0 0 16px;
      padding: .8rem 1.6rem .8rem;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, lg)) {
  .sitewide-appeal-banner {
    &__text {
      &--desktop {
        display: block;
      }

      &--mobile {
        display: none;
      }
    }
  }
}