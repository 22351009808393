.icon-and-doc {
  @include vertical-margin(9, top);
  @include vertical-margin(9, bottom);

  &__image {
    // width: 7rem;
    // height: 7rem;
    margin-bottom: .8rem;
    
    .icon {
      display: block;
      max-height: 100%;
    }
  }

  &__link {
    display: block;
    font-weight: $font-weight--semibold;
    margin-bottom: .4rem;
  }

  &__meta {
    // color: $blue-steel;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .divider {
      width: 1px;
      height: 1.4rem;
      background-color: $warm-grey-3;
      margin: 0 .8rem;
    }
  }

  &--margin-sm {
    @include vertical-margin(7, top);
    @include vertical-margin(7, bottom);
  }
}

.matrix--icon-and-document + .matrix--icon-and-document {
  .icon-and-doc {
    margin-top: map-get($vertical-spacers--mobile, 5) * -1;
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .icon-and-doc {
    display: flex;
    align-items: center;

    &__image {
      flex: 0 0 5rem;
      height: 7rem;
      margin-right: 2.4rem;
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .matrix--icon-and-document + .matrix--icon-and-document {
    .icon-and-doc {
      margin-top: map-get($vertical-spacers--desktop, 7) * -1;
    }
  }
}