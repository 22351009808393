.video {
  @include vertical-margin(10, top);
  @include vertical-margin(10, bottom);
  
  &.cta-spacing {
    @include vertical-margin(6, top);
    @include vertical-margin(4, bottom);
  }

  &__placeholder {
    color: white;
    padding: 0 2rem;
  }

  &__frame {
    position: relative;
    padding-top: 56.25%;
    background-color: black;
    overflow: hidden;
  }

  &__asset {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__button {
    @include button-reset;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    cursor: pointer;
  }

  &__button-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
  }

  &__image {
    display: block;
    opacity: .9;
    transition: all $transition;
  }

  &__play-icon {
    color: $primary-colour;
    width: 7rem;
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
  }

  &:hover {
    .video__button {
      opacity: 1;
    }

    .video__play-icon {
      color: $green-access-dark-10;
    }

    .video__image {
      opacity: .6;
    }
  }

  &__playing {
    .video__image,
    .video__button {
      display: none;
    }
  }

  &__caption {
    color: $blue-steel;
    margin-top: .8rem;
  }

  .accordion__title {
    font-weight: $font-weight--semibold;
  }
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .video {
    &__play-icon {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}