.social-share {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  border-bottom: 2px solid $warm-grey-2;

  &__heading {
    margin-bottom: 1.2rem;
    color: $blue-steel;
  }

  &__links {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -1.2rem;
  }

  &__btn {
    display: flex;
    align-items: center;
    margin-right: 2rem;
    margin-bottom: 1.2rem;

    svg {
      display: inline-block;
    }

    &__copy-link {
      svg {
        margin-right: .8rem;
      }
    }
  }  
}

.hero-spacer {
  & > .social-share {
    margin-left: $container__gutter--sm;
    margin-right: $container__gutter--sm;
    @include vertical-margin(8, bottom);
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .hero-spacer {
    & > .social-share {
      margin-left: $container__gutter--md;
      margin-right: $container__gutter--md;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .social-share {
    display: flex;
    align-items: center;

    &__heading {
      margin-right: 2rem;
      margin-bottom: 0;
      color: $blue-steel;
    }
  }

  .hero-spacer {
    & > .social-share {
      margin-left: $container__gutter--lg;
      margin-right: $container__gutter--lg;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, lg)) {
  .hero-spacer {
    & > .social-share {
      margin-left: $container__gutter--xl;
      margin-right: $container__gutter--xl;
    }
  }
}