.donate-widget {
  position: relative;
  background-color: $white;

  &__tabs {
    @include vertical-padding(7, top);
    @include vertical-padding(7, bottom);

    &__tab {
      & > label {
        color: $link__colour;
        padding: 1.2rem;
        border: 2px solid $primary-colour;
        border-right: none;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1.1;
      }

      &:last-child > label {
        border-right: 2px solid $primary-colour;
      }

      &:hover {
        & > label {
          color: $white;
          background-color: $green-access-dark-10;
          border-color: $green-access-dark-10;
        }
      }

      &:focus {
        outline: none;

        & > label {
          color: $black;
          background-color: $accessibility__highlight;
          border-color: $accessibility__highlight;
        }
      }

      &:active {
        & > label {
          color: $white;
          background-color: $green-access-dark-20;
          border-color: $green-access-dark-20;
        }
      }
    }
  }

  &__option-heading {
    @include vertical-padding(7, top);
    @include vertical-padding(7, bottom);
  }

  input[type=radio] {
    display: none;
  }

  &__option-label {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0 1.4rem;
    border: 2px solid $primary-colour;
    border-radius: $border-radius;
    margin-bottom: 1.6rem;
    color: $blue-mid;
    font-weight: $font-weight--semibold;
    line-height: 2.4rem;
    cursor: pointer;
  }

  &__option-amount {
    text-align: center;
    color: $primary-colour;
    font-family: $heading__font-family;
    font-size: 3.2rem;
    line-height: 4.8rem;
    font-weight: $font-weight--heavy;

    .decimal-points {
      font-size: .5em;
    }

    &--max-length-4 {
      font-size: 2.4rem;
      line-height: 1.1;
    }
  }

  &__option-description {
    font-weight: $font-weight--semibold;
    display: none;
  }

  &__options {
    color: $link__colour;
    font-weight: $font-weight--heavy;
    cursor: pointer;
    display: flex;
    margin-right: -6px;
  }

  &__option {
    flex: 1 1 0;
    width: 0; // hack for same width
    margin-right: 6px;

    &:hover {
      color: $white;

      .donate-widget__option-label {
        color: $white;
        background-color: $green-access-dark-10;
        border-color: $green-access-dark-10;
      }

      .donate-widget__option-amount {
        color: $white;
      }
    }

    &:focus {
      color: $black;
      outline: none;

      .donate-widget__option-label {
        color: $white;
        background-color: $accessibility__highlight;
        border-color: $accessibility__highlight;
      }

      .donate-widget__option-amount {
        color: $black;
      }
    }

    &:active {
      color: $white;

      .donate-widget__option-label {
        color: $white;
        background-color: $green-access-dark-20;
        border-color: $green-access-dark-20;
      }

      .donate-widget__option-amount {
        color: $white;
      }
    }
  }

  &__from-amount {
    position: relative;
    display: flex;
    margin-top: .8rem;

    .currency {
      position: absolute;
      left: 1.6rem;
      top: 1.6rem;
    }

    input {
      margin: 0;
    }

    input[type=number] {
      padding-left: 3.6rem;
      line-height: 4.8rem;
      max-width: 320px;
      border-radius: .4rem;
      // hide number input arrows
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &::placeholder {
        color: $blue-steel;
      }
    }
  }

  &__amount-submit {
    position: relative;
    display: flex;
    margin-top: 1.6rem;

    .currency {
      position: absolute;
      left: 1.6rem;
      top: 1.6rem;
    }

    input {
      margin: 0;
    }

    input[type=number] {
      padding-left: 3.6rem;
      line-height: 4.8rem;
      border-right: none;
      max-width: 206px;
      // hide number input arrows
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &::placeholder {
        color: $body__font-colour;
      }

      &.donate-amount--lg {
        max-width: 320px;
      }
    }

    input[type=submit] {
      box-shadow: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      &:active {
        transform: translateY(0);
      }
    }
  }

  &__amount-message {
    display: none; // changes by donate-widget.js
    margin-top: .8rem;
  }
}

.header-sticky-donation-form .donate-widget__option {
  width: auto;
  margin: 0;
}

@for $i from 1 through 3 {
  // active tab
  .donate-widget {
    &__tabs input[type="radio"]:nth-of-type(#{$i}):checked ~ .tabs__tabs .tabs__tab:nth-of-type(#{$i}) > label {
      color: $white;
      background-color: $primary-colour;
      border-color: $primary-colour;
    }

    // selected option label
    input[type=radio].monthly-amount-#{$i}:checked ~ .donate-widget__options .monthly-amount-#{$i},
    input[type=radio].single-amount-#{$i}:checked ~ .donate-widget__options .single-amount-#{$i},
    input[type=radio].gift-increase-amount-#{$i}:checked ~ .donate-widget__options .gift-increase-amount-#{$i} {
      color: $white;
      background-color: $primary-colour;
      border-color: $primary-colour;

      .donate-widget__option-amount {
        color: $white;
      }

      &:after {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 100%;
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-top: 16px solid $primary-colour;
      }
    }

    // selected option description
    input[type=radio].monthly-amount-#{$i}:checked ~ .donate-widget__option-description-monthly-#{$i},
    input[type=radio].single-amount-#{$i}:checked ~ .donate-widget__option-description-single-#{$i},
    input[type=radio].gift-increase-amount-#{$i}:checked ~ .donate-widget__option-description-gift-increase-#{$i} {
      display: block;
    }
  }
}

// payment icons
.donate-widget__payments-heading {
  font-weight: $font-weight--normal;
  @include vertical-margin(5, top);
  margin-bottom: 8px;
}

.donate-widget__payment-methods {
  display: flex;
  align-items: center;
  margin-right: -8px;
  margin-bottom: 16px;

  img,
  svg {
    margin-right: 8px;
  }
}

.donate-widget__payment-protection {
  display: flex;
  align-items: center;
  margin-right: -8px;

  img,
  svg {
    margin-right: 8px;
  }
}

@media screen and (min-width: 420px) {
  .donate-widget {
    &__option-amount {
      font-size: 4.4rem;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .donate-widget {
    border-radius: $border-radius;

    // &__tabs {
    //   padding-left: 3.2rem;
    //   padding-right: 3.2rem;
    // }

    &__options {
      margin-right: -12px;
    }

    &__option {
      margin-right: 12px;
    }

    &__option-label {
      padding: 2.6rem 1.6rem;
      line-height: 2.8rem;
    }

    &__option-amount {
      font-size: 7rem;
      line-height: 5.6rem;

      &--max-length-4 {
        font-size: 5rem;
        line-height: .8;
      }
    }

    &__from-amount,
    &__amount-submit {
      margin-top: 1.6rem;

      .currency {
        top: 1.2rem;
      }
    }
  }

  .donate-widget__payment-icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .donate-widget__payment-methods {
    display: flex;
    margin-bottom: 0;
  }

  .donate-widget__payment-protection {
    display: flex;
  }
}

@media screen and (min-width: map-get($breakpoints, xl)) {
  .donate-widget {
    max-width: $container-inner-sm__max-width;

    &__tabs {
      padding-left: 3.2rem;
      padding-right: 3.2rem;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, xxl)) {
  .donate-widget {
    max-width: none;
  }
}