.donation-banner-callout {
    background: $pink;
    margin: 0 auto;
    overflow: hidden;
    -webkit-box-shadow: 9px 9px 13px -7px rgba(51, 51, 51, 0.75);
    -moz-box-shadow: 9px 9px 13px -7px rgba(51, 51, 51, 0.75);
    box-shadow: 9px 9px 13px -7px rgba(51, 51, 51, 0.75);
    max-width: $container__max-width;
    @include vertical-margin(8, bottom);

    &.blue-steel {
        background: $blue-steel;
    }

    &_content {
        padding: $container__gutter--sm;
        color: $white;

        h3 {
            color: $white;
            @include vertical-margin(3, bottom);
        }

        p {
            max-width: $container-inner__max-width;
        }

        .button {
            margin-top: 0;
            margin-bottom: 0;
            white-space: nowrap;
        }
    }

    @media screen and (min-width: map-get($breakpoints, xl)) {

        &_content {
            padding: $container__gutter--lg;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .donation-banner-callout_text {
                margin-right: $container__gutter--lg;
            }
    
            p {
                margin: 0;
            }

            .urgent-popup-cta__button {
                min-width: 20rem;
            }
        }
    }
}

.site-hero.donation-banner {
    .site-hero__spacer {
        .hero-spacer {
            background-color: transparent;

            + .site-hero__spacer--hero-image {
                display: none;
            }
        }

        + .donation-banner-callout-container {
            z-index: 1;
            margin-top: -6rem;
            position: relative;
        }
    }

    // @media screen and (min-width: map-get($breakpoints, xl)) {
    //     .site-hero__spacer {
    //         + .donation-banner-callout-container {
    //             margin-top: -8.8rem;
    //         }
    //     }
    // }
}

// News/blog specific styles
.donation-banner-callout-container.article {
    .donation-banner-callout {
        max-width: $container-inner__max-width;
        margin-left: 0;
        margin-right: 0;

        &_content {
            p {
                max-width: $container-inner-sm__max-width;
            }
        }

        @media screen and (min-width: map-get($breakpoints, xl)) {
            max-width: $container-inner-xl__max-width;
        }
    }
}

// Homepage specific styles
.matrix--cta-stripe.donation-banner  {
    + .matrix {
        padding-top: 130px;
    }
  
    .donation-banner-callout-container {
        padding-left: 0;
        padding-right: 0;
        position: relative;
        bottom: -100px;
        margin-top: -100px;
    }

    .donation-banner-callout {
        margin-bottom: 0;
    }
}