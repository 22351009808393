.cta-block {
  color: $white;
  padding: 2rem 2.4rem 2.8rem;
  background-color: $blue-steel;
  border-radius: $border-radius;
  @include vertical-margin(10, top);
  @include vertical-margin(10, bottom);

  & .py-64 {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
  }

  &__image {
    aspect-ratio: 16/9;
    margin-top: -20rem;
    margin-bottom: 4.8rem;
    background: black
  }

  &.matrix--call-to-action {
    margin-top: 0;
  }

  &__heading {
    color: $white;
    margin-bottom: 2rem;
  }

  &__button {
    margin-top: 0;
  }

  &__arrow {
    margin-left: 1rem;
  }

  &__postcode {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
  }

  &__spacing {
    margin-top: 24rem;
  
    @media (min-width: 1024px) {
      margin-top: 38rem;
    }
  
    @media (min-width: 1280px) {
      margin-top: 42rem;
    }
  }
  
}

//------//
// > md //
//------//

@media screen and (min-width: map-get($breakpoints, md)) {
  .cta-block {
    padding: 2.4rem 2.8rem 3.2rem;

    &__heading {
      margin-bottom: 2.4rem;
    }
  }
}

//------//
// > xl //
//------//

@media screen and (min-width: map-get($breakpoints, xl)) {
  .cta-block {
    padding: 3.2rem 4rem 4.4rem;

    &.px-0 {
      padding-left: 0;
      padding-right: 0;
    }

    & .py-64 {
      padding-top: 6.4rem;
      padding-bottom: 6.4rem;
    }

    &--inline {
      width: fit-content;
    }

    &__heading {
      margin-bottom: 3.2rem;
    }

    &__postcode {
      display: inline-flex;
      flex-wrap: wrap;
      min-width: $container-inner__max-width;
      align-items: center;
      padding-top: .8rem;

      .cta-block__heading {
        margin-right: 4.8rem;
        margin-bottom: 0;
      }
    }

    &__image {
      margin-top: -40rem;
    }
  }
}