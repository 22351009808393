.container {
  width: 100%;
  max-width: $container__max-width;
  padding-left: $container__gutter--sm;
  padding-right: $container__gutter--sm;
  margin-left: auto;
  margin-right: auto;
}

.container-inner {
  width: 100%;
  max-width: $container-inner__max-width;
}

main {
  padding-top: 80px; // fixed heading height
  &.with-information-notice {
    padding-top: calc(80px + 68px);
  }
}

.container__standfirst  {
  & + .matrix--text {
    p:first-child {
      @include vertical-margin(7, top);
    }
  }

  & + .matrix--simple-link {
    .simple-link:first-child {
      @include vertical-margin(7, top);
    }
  }
}

.sitewide-donate-widget-container {
  @include vertical-padding(9, top);
  @include vertical-padding(9, bottom);
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .container {
    padding-left: $container__gutter--md;
    padding-right: $container__gutter--md;
  }
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .container {
    padding-left: $container__gutter--lg;
    padding-right: $container__gutter--lg;
  }

  main {
    padding-top: 120px; // fixed heading height
    &.with-information-notice {
      padding-top: calc(120px + 80px);
    }
  }
}

@media screen and (min-width: map-get($breakpoints, lg)) {
  .container {
    padding-left: $container__gutter--xl;
    padding-right: $container__gutter--xl;
  }

  main {
    padding-top: 206px; // fixed heading height
    &.with-information-notice {
      padding-top: calc(206px + 80px);
    }
  }

  // Pages with reduced header (e.g. donation form)
  .site-header--reduced ~ main {
    padding-top: 140px;
  }
}

@media screen and (min-width: map-get($breakpoints, xl)) {
  .container-inner {
    &--sm {
      max-width: $container-inner-sm__max-width;
    }

    &--lg {
      max-width: $container-inner-lg__max-width;
    }

    &--xl {
      max-width: $container-inner-xl__max-width;
    }

    &--xxl {
      max-width: $container-inner-xxl__max-width;
    }

    &--fullwidth {
      max-width: none;
    }
  }
}