.accordion {
  @include vertical-margin(9, top);
  @include vertical-margin(9, bottom);
  border-top: 1px solid $warm-grey-2;
  border-bottom: 1px solid $warm-grey-2;

  .accordion__arrow {
    position: absolute;
    left: 0;
    top: 2.9rem;
  }

  .accordion__speech-bubble {
    position: absolute;
    left: .2rem;
    top: 2.4rem;
  }

  &__title {
    margin-bottom: 0;
  }

  &__title-meta {
    color: $blue-steel;
    font-weight: $font-weight--semibold;
  }
  
  &__trigger {
    position: relative;
    @include button-reset();
    padding: 2rem 0 2rem 2.8rem;
    color: $link__colour;
    cursor: pointer;

    .inline-link {
      display: inline-block;
    }

    &:hover {
      .inline-link {
        color: $accessibility__text;
        text-decoration: $link__text-decoration;
        text-decoration-thickness: $link__text-decoration-thickness;
        text-underline-offset: $link__text-underline-offset;
      }
    }

    &:active, 
    &:focus {
      outline: none;

      .inline-link {
        color: $accessibility__text;
        text-decoration: none;
        background-color: $accessibility__highlight;
        box-shadow: $accessibility__box-shadow;
      }
    }
  }

  &.expanded {
    .accordion__arrow {
      top: 2.8rem;
      transform: rotate(180deg);
    }
  }

  &__body {
    display: none;
    @include vertical-padding(2, top);
    @include vertical-padding(6, bottom);

    .container, 
    .container-inner {
      padding: 0;
      margin: 0;
      max-width: none;
    }

    .matrix--text:last-child {
      p:last-child {
        @include vertical-margin(1, bottom);
      }
    }
  }

  &__q-and-a {
    border: 2px solid $primary-colour;
    border-radius: $border-radius;
    background-color: $white;

    .accordion__arrow {
      left: 2.4rem;
      top: 2.7rem;
    }

    .question-mark {
      display: none;
    }

    .accordion__trigger {
      padding: 1.8rem 1.8rem 1.8rem 5rem;
    }

    .accordion__body {
      padding-left: 2.2rem;
      padding-right: 2.2rem;
    }

    &.expanded {
      .accordion__arrow {
        top: 2.6rem;
      }
    }
  }

  &__video {
    margin-top: 0;
    margin-bottom: 0;
    border-top: none;
    
    .accordion__trigger {
      padding-left: 3.2rem;
    }
  }

  .icon-and-link {
    @include vertical-margin(6, top);
    @include vertical-margin(6, bottom);
    padding: 0;
    border: none;
  }
}

.matrix--accordion + .matrix--accordion {
  .accordion {
    margin-top: map-get($vertical-spacers--mobile, 9) * -1;
    border-top: none;
  }
}

.matrix--q-and-a + .matrix--q-and-a {
  .accordion {
    margin-top: map-get($vertical-spacers--mobile, 8) * -1;
  }
}

.accordion__q-and-a + .accordion__q-and-a {
  margin-top: map-get($vertical-spacers--mobile, 8) * -1;
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .matrix--accordion + .matrix--accordion {
    .accordion {
      margin-top: map-get($vertical-spacers--desktop, 9) * -1;
    }
  }
  
  .matrix--q-and-a + .matrix--q-and-a {
    .accordion {
      margin-top: map-get($vertical-spacers--desktop, 8) * -1;
    }
  }

  .accordion__q-and-a + .accordion__q-and-a {
    margin-top: map-get($vertical-spacers--desktop, 8) * -1;
  }

  .accordion {
    .accordion__arrow {
      top: 3.5rem;
    }

    .accordion__speech-bubble {
      top: 3rem;
    }

    &__trigger {
      padding: 2.4rem 0 2.4rem 2.8rem;
    }

    &.expanded {
      .accordion__arrow {
        top: 3.4rem;
      }
    }

    &__q-and-a {
      .accordion__arrow {
        left: 3rem;
        top: 4.5rem;
      }

      .question-mark {
        display: block;
        position: absolute;
        right: 2.2rem;
        top: 2.2rem;
        color: $primary-colour;
      }
  
      .accordion__trigger {
        padding: 3.4rem 10.2rem 3.4rem 5.8rem;
      }

      .accordion__body {
        padding-left: 2.6rem;
        padding-right: 2.6rem;
      }

      &.expanded {
        .accordion__arrow {
          top: 4.4rem;
        }
      }
    }
  }
}