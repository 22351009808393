.jump-links {
  @include vertical-margin(9, top);
  @include vertical-margin(9, bottom);
  @include vertical-padding(6, top);
  @include vertical-padding(6, bottom);
  border: 2px solid $blue-steel-tint-10;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  border-radius: $border-radius;

  &:first-child {
    margin-top: 0;
  }

  &__label {
    color: $black;
    margin-bottom: .8rem;
  }

  &__list {
    @include list-reset();

    li>a {
      position: relative;
      display: block;
      padding-left: 2.4rem;
      margin-top: .8rem;

      .icon-arrow {
        position: absolute;
        left: 0;
        top: .8rem;
      }
    }
  }
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .jump-links {
    padding-left: 2.8rem;
    padding-right: 2.8rem;
  }
}