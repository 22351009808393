.related-action-links {
  @include vertical-padding(9, top);
  @include vertical-padding(10, bottom);

  // &:last-child {
  //   padding-bottom: 0;
  // }

  &--less-padding {
    @include vertical-padding(2, top);
    @include vertical-padding(2, bottom);
    margin-bottom: map-get($vertical-spacers--mobile, 4) * -1;
  }
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .related-action-links {
    &--less-padding {
      margin-bottom: map-get($vertical-spacers--desktop, 4) * -1;
    }
  }
}