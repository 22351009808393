.icon-and-para-card {
  border: 2px solid $warm-grey-2;
  border-radius: $border-radius;
  padding: 1.8rem 2.2rem 2.2rem;
  height: 100%;

  &__image {
    width: 7rem;
    height: 7.8rem;
    margin-bottom: 1.6rem;

    .icon {
      display: block;
      max-height: 100%;
      margin-top: .4rem;
      margin-bottom: .4rem;
    }
  }

  &__heading {
    color: $blue-steel;
    margin-bottom: 0;
  }
}

.icon-and-para-cards {
  @include list-reset();
  @include vertical-margin(9, top);
  @include vertical-margin(10, bottom);
}

.matrix--icon-and-paragraph + .matrix--icon-and-paragraph {
  .icon-and-para {
    @include vertical-padding(7, top);
    margin-top: map-get($vertical-spacers--mobile, 2) * -1;
    border-top: 1px solid $blue-light;
  }
}

.site-content__body:nth-child(even) {
  .icon-and-para-card {
    border: none;
    background-color: $white;
  }
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .icon-and-para-card {
    padding: 2.2rem 2.6rem;
  }

  .matrix--icon-and-paragraph + .matrix--icon-and-paragraph {
    .icon-and-para {
      margin-top: map-get($vertical-spacers--desktop, 2) * -1;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, xl)) {
  .icon-and-para-card {
    display: flex;
  
    &__image {
      flex: 0 0 7rem;
      margin-right: 2.4rem;
      @include vertical-margin(1, top);
    }
  }
}