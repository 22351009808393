.vimeo-wrapper {
  @include vertical-margin(9, top);
  @include vertical-margin(10, bottom);
}

.vimeo-wrapper__container {
  position: relative;
  @include vertical-margin(5, top);
  padding-top: 56.25%;
  overflow: hidden;
}

.vimeo-wrapper__iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}