.entry-card {
  border: 2px solid $primary-colour;
  padding: 1.8rem 2.2rem 2.2rem;
  border-radius: $border-radius;
  height: 100%;
  min-height: 124px;
  background-color: $white;
  cursor: pointer;

  &__thumbnail {
    position: relative;
    height: 160px;
    margin: -1.8rem -2.2rem 2rem;

    .thumbnail {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      object-fit: cover;
      height: 100%;
      width: 100%;
      // border-radius: .6rem .6rem 0 0;
    }
  }

  &__heading {
    @include vertical-margin(4, bottom);

    .icon-arrow {
      vertical-align: -1px;
      margin-left: 2px;
    }
  }

  &__excerpt {
    color: $body__font-colour;
  }

  &__meta {
    color: $blue-steel;
    @include vertical-margin(2, top);

    .tags {
      display: inline-flex;
      margin-right: .4rem;

      &:last-child {
        display: flex;
        margin-right: 0;
      }
    }

    .post-date {
      vertical-align: -2px;
    }
  }

  &:hover {
    border-color: $green-access-dark-10;

    .entry-card__heading {
      a {
        color: $accessibility__text;
        text-decoration: $link__text-decoration;
        text-decoration-thickness: $link__text-decoration-thickness;
        text-underline-offset: $link__text-underline-offset;
      }
    }
  }

  &:active {
    border-color: $accessibility__text;

    .entry-card__heading {
      a {
        color: $accessibility__text;
        text-decoration: none;
        background-color: $accessibility__highlight;
        box-shadow: $accessibility__box-shadow;
      }
    }
  }

  &--blue {
    color: $white;
    background-color: $blue-mid;
    border-color: $blue-mid;

    .entry-card__heading a {
      color: $white;
    }

    &:hover {
      background-color: $blue-mid-dark-10;
      border-color: $blue-mid-dark-10;

      .entry-card__heading a {
        color: $white;
        text-decoration: none;
      }
    }

    &:active {
      border-color: $blue-mid;

      .entry-card__heading a {
        color: $accessibility__text;
        background-color: $accessibility__highlight;
        box-shadow: $accessibility__box-shadow;
      }
    }
  }

  &--landscape {
    min-height: 0;

    &--simple {
      border: none;
      padding: 0;

      .entry-card__heading {
        font-weight: $font-weight--semibold;
        margin-bottom: 0;
      }

      .entry-card__excerpt {
        color: $black;
      }
    }
  }
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .entry-card {
    padding: 2.2rem 2.6rem;
    min-height: 144px;

    &__thumbnail {
      margin: -2.2rem -2.6rem 2rem;
    }

    &--landscape {
      display: flex;
      min-height: 0;

      &--no-image {
        max-width: 576px;
      }

      .entry-card__thumbnail {
        width: 160px;
        min-height: 152px;
        flex: 0 0 160px;
        height: calc(100% + 4.4rem);
        margin: -2.2rem 2.8rem -2.2rem -2.6rem;
      }

      // .thumbnail {
      //   border-radius: .6rem 0 0 .6rem;
      // }

      .entry-card__heading {
        margin-bottom: 0;
      }

      .entry-card__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      &--simple {
        padding: 0;
      }
    }
  }
}

@media screen and (min-width: map-get($breakpoints, lg)) {
  .entry-card {
    &--large {
      .entry-card__thumbnail {
        height: 260px;
      }
    }

    &--landscape {
      display: flex;

      .entry-card__thumbnail {
        width: 264px;
        flex: 0 0 264px;
      }
    }
  }
}