.cta-stripe {
  @include vertical-padding(7, top);
  @include vertical-padding(7, bottom);

  &__text {
    font-weight: $font-weight--semibold;
    @include vertical-margin(6, bottom);
  }

  &__link {
    padding-bottom: 4px;

    .button {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.matrix--cta-stripe {
  color: $white;
  background-color: $primary-colour;
}

@media screen and (min-width: map-get($breakpoints, xl)) {
  .cta-stripe {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  
    &__text {
      flex: 0 0 75%;
      margin-bottom: 0;
    }
  
    &__link {
      flex: 0 0 25%;
    }
  }
}