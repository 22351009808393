.entry-card-section {
  color: $blue-steel;

  &__heading-and-text {
    @include vertical-margin(9, bottom);
  }

  &__heading {
    @include vertical-margin(6, bottom);
  }
}

.matrix--entry-card-section {
  @include vertical-padding(9, top);
  @include vertical-padding(9, bottom);
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .entry-card-section {
    &__heading {
      display: flex;
      flex-wrap: wrap;

      .icon {
        flex: 0 0 70px;
        margin-right: 2.4rem;
      }

      h2 {
        margin-top: .6rem;
      }
    }
  }
}