.tabs {
  &__tabs {
    @include list-reset();
    display: flex;
  }

  &__tab {
    flex: 1 1 0;

    & > label {
      cursor: pointer;
      display: block;
      margin-bottom: 0;
      text-align: center;
    }
  }

  &__content {
    display: none;
  }
}

@for $i from 1 through 3 {
  .tabs input[type="radio"]:nth-of-type(#{$i}):checked ~ .tabs__content:nth-of-type(#{$i}) {
    display: block;
  }
}