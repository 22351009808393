.appeal-donation-widget {
  position: relative;
  background-color: $pink;

  &__image {
    position: relative;

    img {
      display: block;
    }

    &-text {
      position: absolute;
      left: 0;
      bottom: 0;
      display: inline-block;
      max-width: 80%;
      color: $white;
      background-color: $green-access;
      padding: 6px 16px;
    }
  }

  &__widget {
    border: 2px solid $pink;
  }

  &__widget-text {
    padding: $container__gutter--md;
    color: $white;

    h3 {
      color: $white;
      margin-bottom: 8px;
    }
  }

  .donate-widget {
    padding: 0 $container__gutter--md;
  }

  .donate-widget__tabs {
    padding: $container__gutter--md 0;
  }

  .site-hero__donate-widget .hero-image {
    display: none;
  }

  .site-hero__donate-widget {
    padding: 0;

    & > .container {
      padding: 0;
      max-width: none;

      & > .container-inner {
        max-width: none;
      }
    }
  }

  .donate-widget__option-amount {
    font-size: 3.2rem;
    line-height: 3.6rem;
  }

  .donate-widget__option-label {
    padding: 1.2rem;
  }
}

.appeal-donation-widget__tag-wrap {
  position: relative;
  height: 12px;
  z-index: 1;
}

.appeal-donation-widget__tag {
  display: inline-block;
  color: $white;
  background-color: $pink;
  padding: 6px 16px 4px;
  font-weight: $font-weight--bold;
  text-transform: uppercase;
  max-width: 360px;
}

@media screen and (min-width: map-get($breakpoints, lg)) {
  .appeal-donation-widget {
    display: flex;
    justify-content: space-between;
    padding: $container__gutter--lg;

    &__image {
      flex: 0 0 calc(50% + #{$container__gutter--lg} - #{$container__gutter--sm});
      margin: (-$container__gutter--lg) 0 (-$container__gutter--lg) (-$container__gutter--lg);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__widget {
      flex: 0 0 calc(50% - #{$container__gutter--sm});
      border: none;
    }

    &__widget-text {
      padding: 0 0 $container__gutter--md;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, xxl)) {
  .appeal-donation-widget {
    .donate-widget__option-amount {
      font-size: 4rem;
      line-height: 4.2rem;
    }

    .donate-widget__option-label {
      padding: 1.6rem 1.2rem;
    }
  }
}