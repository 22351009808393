.stats {
  // color: $blue-steel;
  @include vertical-margin(8, top);
  @include vertical-margin(8, bottom);
  
  &__icon {
    img {
      display: block;
      @include vertical-margin(2, bottom);
    }
  }
  
  &__statistic {
    @include vertical-margin(2, bottom);
  }
}