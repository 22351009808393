.list-entries {
  @include vertical-padding(9, top);
}

.list-filter-trigger {
  // margin-top: 0;
  margin-bottom: 0;
}

.list-filter-tag-remove {
  @include vertical-padding(9, top);
}

.list-filter {
  position: relative;
  padding: 1.6rem 1.6rem 2rem;
  border-radius: $border-radius;
  background-color: $white;

  &__cat-list {
    @include vertical-margin(7, bottom);
  }

  &__cat-list-title {
    @include vertical-margin(6, bottom);
  }

  &__list-link {
    display: flex;
    align-items: center;
  }

  &__cat-tags {
    li {
      input[type=checkbox] + .list-filter__cat-tag {
        color: $link__colour;
        border: 2px solid $primary-colour;
        cursor: pointer;

        &:hover {
          color: $white;
          background-color: $green-access-dark-10;
          border-color: $green-access-dark-10;
        }

        &:active {
          color: $white;
          background-color: $green-access-dark-20;
          border-color: $green-access-dark-20;
        }
      }

      input[type=checkbox]:checked + .list-filter__cat-tag {
        color: $white;
        background-color: $primary-colour;

        &:hover {
          color: $white;
          background-color: $green-access-dark-10;
          border-color: $green-access-dark-10;
          box-shadow: none;
        }
    
        &:active {
          color: $white;
          background-color: $green-access-dark-20;
          border-color: $green-access-dark-20;
          box-shadow: none;
        }
      }
    }
  }

  // applied tags
  &__cat-tags-remove {
    li {
      input[type=checkbox] + .list-filter__cat-tag__remove, 
      input[type=checkbox]:checked + .list-filter__cat-tag__remove {
        color: $link__colour;
        border: 2px solid $primary-colour;
        background-color: $white;
        cursor: pointer;

        &:hover {
          color: $accessibility__text;
          background-color: $white;
          border-color: $green-access-dark-10;
        }

        &:active {
          color: $accessibility__text;
          background-color: $white;
          border-color: $green-access-dark-20;
        }
      }

      &:nth-last-child(2) {
        @include vertical-margin(5, right);
      }
    }
  }

  &__submit-button {
    @include vertical-margin(2, top);
  }
}

.list-filter-pop-up {
  //show/hide
  position: fixed;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 200; //higher than header 100 (site-header)
  top: -99999px;
  left: -99999px;
  right: 99999px;
  transform: translateY(1rem);
  opacity: 0;
  transition: opacity $transition__duration ease-in-out, transform $transition__duration ease-in-out, top 0s $transition__duration, left 0s $transition__duration, right 0s $transition__duration;

  &.active {
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(0);
    opacity: 1;
    transition: opacity $transition__duration ease-in-out, transform $transition__duration ease-in-out;
  }

  &__screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
  }

  // mobile scroll fix
  & > .container {
    max-height: 100%;
    overflow: auto;
    padding-top: $container__gutter--sm;
    padding-bottom: $container__gutter--sm;
  }
}

.list-filter-close {
  position: absolute;
  display: flex;
  top: 1.6rem;
  right: 1.6rem;
  height: 3.2rem;
  align-items: center;
  color: $link__colour;
  cursor: pointer;

  &__icon {
    width: 1.8rem;
    height: 1.8rem;
    color: $primary-colour;
  }

  &:hover {
    color: $accessibility__text;

    .list-filter-close__icon {
      color: $green-access-dark-10;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .list-filter-pop-up {
    & > .container {
      padding-top: $container__gutter--md;
      padding-bottom: $container__gutter--md;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .list-filter-pop-up {
    & > .container {
      padding-top: $container__gutter--lg;
      padding-bottom: $container__gutter--lg;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, lg)) {
  .list-filter {
    padding: 3.2rem 3.2rem 3.6rem;
  }

  .list-filter-close {
    top: 3.2rem;
    right: 3.2rem;
    height: 4rem;
  }
}