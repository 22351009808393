html {
  font-size: 62.5%;
}

body {
  font-family: $body__font-family;
  color: $body__font-colour;
  font-size: $body__font-size--mobile;
  line-height: $body__line-height--mobile;
  font-weight: $body__font-weight;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  font-feature-settings: "pnum" on, "lnum" on, "ss04" on;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  @include vertical-margin(6, bottom);
}

.color-blue-steel {
  color: $h1__colour;
}

h1,
.h1, 
.heading-xxl {
  font-family: $heading__font-family;
  color: $h1__colour;
  font-weight: $h1__font-weight;
  font-size: $h1__font-size--mobile;
  line-height: $h1__line-height--mobile;
}

h2,
.h2, 
.heading-xl {
  color: $h2__colour;
  font-weight: $h2__font-weight;
  font-size: $h2__font-size--mobile;
  line-height: $h2__line-height--mobile;
}

h3,
.h3, 
.heading-lg {
  color: $h3__colour;
  font-weight: $h3__font-weight;
  font-size: $h3__font-size--mobile;
  line-height: $h3__line-height--mobile;
}

.heading-xxl {
  @include vertical-margin(8, bottom);
  font-family: $heading__font-family;
  font-size: $h-xxl__font-size--mobile;
  line-height: $h-xxl__line-height--mobile;
}

.heading-md {
  font-size: $h-md__font-size--mobile;
  line-height: $h-md__line-height--mobile;
}

.heading-sm {
  font-weight: $h-sm__font-weight;
  font-size: $h-sm__font-size--mobile;
  line-height: $h-sm__line-height--mobile;
}

.heading-xs {
  font-weight: $h-xs__font-weight;
  font-size: $h-xs__font-size--mobile;
  line-height: $h-xs__line-height--mobile;
}

.heading-stat {
  color: $stat__colour;
  font-family: $stat__font-family;
  font-weight: $stat__font-weight;
  font-size: $stat__font-size--mobile;
  line-height: $stat__line-height--mobile;
}

.body-xl {
  font-family: $body-xl__font-family;
  font-weight: $body-xl__font-weight;
  font-size: $body-xl__font-size--mobile;
  line-height: $body-xl__line-height--mobile;
}

.body-md {
  font-size: $body-md__font-size--mobile;
  line-height: $body-md__line-height--mobile;
}

.standfirst {
  // color: $stf__colour;
  // font-weight: $stf__font-weight;
  font-size: $stf__font-size--mobile;
  line-height: $stf__line-height--mobile;
  @include vertical-margin(7, bottom);
}

.blockquote {
  margin: 0;
}

.quote {
  font-family: $quote__font-family;
  font-weight: $quote__font-weight;
  font-size: $quote__font-size--mobile;
  line-height: $quote__line-height--mobile;
  @include vertical-margin(2, bottom);

  .quote-mark {
    //vertical-align: -2px;

    &:first-child {
      margin-right: .2rem;
    }

    &:last-child {
      margin-left: .2rem;
    }
  }
}

.quote-source, 
.breadcrumb, 
.caption, 
.body-xs {
  font-size: $body-xs__font-size--mobile;
  line-height: $body-xs__line-height--mobile;
}

a, 
.inline-link {
  color: $link__colour;
  text-decoration: none;
  transition: color $transition;
  
  &:hover {
    color: $accessibility__text;
    text-decoration: $link__text-decoration;
    text-decoration-thickness: $link__text-decoration-thickness;
    text-underline-offset: $link__text-underline-offset;
  }

  &:focus {
    color: $accessibility__text !important;
    text-decoration: none !important;
    background-color: $accessibility__highlight;
    box-shadow: $accessibility__box-shadow;
  }

  &.no-hover {
    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }

  &.no-focus {
    &:focus {
      color: inherit !important;
      background-color: transparent;
      box-shadow: none;
    }
  }
}

.inline-link {
  cursor: pointer;

  &:active {
    color: $accessibility__text !important;
    text-decoration: none;
    background-color: $accessibility__highlight;
    box-shadow: $accessibility__box-shadow;
  }
}

.container__standfirst + .matrix--heading {
  h2, 
  h3 {
    @include vertical-padding(9, top);
  }
}

.matrix + .matrix--heading {
  h2, 
  h3 {
    @include vertical-padding(4, top);
  }
}

.matrix--text:last-child {
  .rich-text {
    p:last-child {
      margin-bottom: 0;
    }
  }
}

.list-header {
  @include vertical-margin(2, bottom);
  color: $body__font-colour;
  font-weight: $h-sm__font-weight;
  font-size: $h-sm__font-size--mobile;
  line-height: $h-sm__line-height--mobile;
}

.rich-text {
  ul,
  ol {
    @include list-reset();
    @include vertical-margin(6, bottom);
    font-size: $body-sm__font-size--mobile;
    line-height: $body-sm__line-height--mobile;
  }

  li {
    position: relative;
    padding-left: 2.8rem;
    margin-bottom: 0.8rem;
    @include last-el-no-bottom-margin;

    &:before {
      content: "";
      position: absolute;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  ul li:before {
    left: 0.2rem;
    display: block;
    width: 1rem;
    height: 1rem;
    top: 1rem;
    background-color: $primary-colour;
    transform: rotate(45deg);
    // border-radius: 100%;
  }

  ol {
    counter-reset: dots;

    li:before {
      counter-increment: dots;
      content: counter(dots, decimal) ".";
      color: $blue-steel;
      font-weight: $font-weight--semibold;
      width: 3rem; // https://trello.com/c/tmukksef/521-formatting-issue-on-numbered-lists
      text-align: right;
      left: -1rem;
    }
  }

  &--invert {
    a, 
    .inline-link {
      color: $warm-grey-2;

      &:hover {
        color: $white;
      }
    }

    ul li:before {
      background-color: $white;
    }

    ol {  
      li:before {
        color: $white;
      }
    }
  }
}

.simple-link {
  @include vertical-margin(6, bottom);
  
  &>a {
    font-weight: $font-weight--semibold;
  }

  .arrow {
    vertical-align: -3px;
    margin-left: .4rem;
  }
}

.tags {
  @include list-reset();
  display: flex;
  flex-wrap: wrap;

  &--sm {
    margin: -.2rem;
  }

  &--lg {
    margin: -.4rem;
  }
}

.tag {
  font-weight: $font-weight--medium;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  
  &--sm {
    font-size: 1.5rem;
    line-height: 1.6rem;
    padding: .3rem .6rem;
    // border-radius: .2rem;
    margin: .2rem;

    &.tag--link {
      padding: 0;

      a {
        display: block;
        color: $white;
        padding: .3rem .6rem;
      }
    }
  }

  &--lg {
    font-size: $h-sm__font-size--mobile;
    line-height: $h-sm__line-height--mobile;
    font-weight: $font-weight--semibold;
    text-transform: none;
    letter-spacing: normal;
    padding: .8rem 1.4rem;
    // border-radius: .4rem;
    margin: .4rem;
  
    .tag-icon-left {
      margin-left: -.4rem;
      margin-right: .8rem;
    }

    &.tag--link {
      padding: 0;

      a {
        display: block;
        color: $white;
        padding: .8rem 1.4rem;
      }
    }
  }

  &--blue {
    color: $white;
    background-color: $blue-mid;
  }

  &--blue-steel {
    color: $blue-steel;
    background-color: $blue-steel-tint-10;
  }
}

.post-date {
  display: inline-block;

  &__icon {
    vertical-align: -1px;
  }
}

//------//
// > md //
//------//

@media screen and (min-width: map-get($breakpoints, md)) {
  body {
    font-size: $body__font-size--desktop;
    line-height: $body__line-height--desktop;
  }

  h1,
  .h1 {
    font-size: $h1__font-size--desktop;
    line-height: $h1__line-height--desktop;
  }

  h2,
  .h2, 
  .heading-xl {
    font-size: $h2__font-size--desktop;
    line-height: $h2__line-height--desktop;
  }

  h3,
  .h3, 
  .heading-lg {
    font-size: $h3__font-size--desktop;
    line-height: $h3__line-height--desktop;
  }

  .heading-xxl {
    font-size: $h-xxl__font-size--desktop;
    line-height: $h-xxl__line-height--desktop;
  }

  .heading-md {
    font-size: $h-md__font-size--desktop;
    line-height: $h-md__line-height--desktop;
  }

  .heading-sm {
    font-size: $h-sm__font-size--desktop;
    line-height: $h-sm__line-height--desktop;
  }

  .heading-xs {
    font-size: $h-xs__font-size--desktop;
    line-height: $h-xs__line-height--desktop;
  }

  .heading-stat {
    font-size: $stat__font-size--desktop;
    line-height: $stat__line-height--desktop;
  }

  .body-xl {
    font-size: $body-xl__font-size--desktop;
    line-height: $body-xl__line-height--desktop;
  }

  .body-md {
    font-size: $body-md__font-size--desktop;
    line-height: $body-md__line-height--desktop;
  }

  .standfirst {
    font-size: $stf__font-size--desktop;
    line-height: $stf__line-height--desktop;
  }

  .quote {
    font-size: $quote__font-size--desktop;
    line-height: $quote__line-height--desktop;
  }

  .quote-source, 
  .breadcrumb, 
  .caption, 
  .body-xs {
    font-size: $body-xs__font-size--desktop;
    line-height: $body-xs__line-height--desktop;
  }

  .list-header {
    font-size: $h-sm__font-size--desktop;
    line-height: $h-sm__line-height--desktop;
  }

  .rich-text {
    ul,
    ol {
      font-size: $body-sm__font-size--desktop;
      line-height: $body-sm__line-height--desktop;
    }
  }

  .tag {
    &--lg {
      font-size: $h-sm__font-size--desktop;
      line-height: $h-sm__line-height--desktop;
      padding: 1rem 2.2rem;

      .tag-icon-left {
        margin-left: -.8rem;
      }

      &.tag--link {
        padding: 0;
  
        a {
          padding: 1rem 2.2rem;
        }
      }
    }
  }
}
