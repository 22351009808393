.content-image {
  @include vertical-margin(10, top);
  @include vertical-margin(10, bottom);

  &__asset {
    display: block;
    height: auto;
  }

  &__caption {
    color: $blue-steel;
    margin-top: .8rem;
  }
}