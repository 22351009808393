.job-details {
  @include list-reset();
  // color: $blue-steel;
  @include vertical-margin(7, bottom);

  li {
    padding-bottom: .8rem;
    margin-bottom: .8rem;
    border-bottom: 2px solid $warm-grey-2;

    strong {
      display: block;
      margin-bottom: .4rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.tags + .job-details {
  @include vertical-margin(6, top);
}

.job-application-link {
  @include vertical-margin(7, top);
  @include vertical-margin(7, bottom);

  & > a {
    margin: 0;
  }
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .job-details {
    li {
      display: flex;

      strong {
        flex: 0 0 176px;
      }
    }
  }
}