@mixin button($btn__text-colour, $btn__text-colour--hover, $btn__background-colour, $btn__shadow-colour, $btn__background-colour--hover, $btn__shadow-colour--hover, $btn__background-colour--active) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: $btn__background-colour;
  color: $btn__text-colour;
  outline: 0;
  border-radius: $btn__border-radius;
  box-shadow: 0 4px $btn__shadow-colour;
  padding: $btn__padding;
  @include vertical-margin(8, top);
  @include vertical-margin(8, bottom);
  font-weight: $btn__font-weight;
  transition: all $transition;
  border: none;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 0.5em;
  }

  &:hover {
    color: $btn__text-colour--hover !important;
    text-decoration: none;
    background-color: $btn__background-colour--hover;
    box-shadow: 0 4px $btn__shadow-colour--hover;
  }

  &:active {
    color: $btn__text-colour--hover !important;
    background-color: $btn__background-colour--active;
    box-shadow: none;
    transform: translateY(4px);
  }

  &:disabled {
    opacity: .5;
    cursor: not-allowed;
  }

  &.button--slim {
    padding: $btn__padding--slim;
  }

  &.button--mb-0 {
    margin-bottom: 0;
  }

  @media screen and (min-width: map-get($breakpoints, md)) {
    padding: $btn__padding--desktop;

    &.button--slim {
      padding: $btn__padding--slim--tablet;
    }
  }

  @media screen and (min-width: map-get($breakpoints, lg)) {
    &.button--slim {
      padding: $btn__padding--slim--desktop;
    }
  }
}

.button {
  @include button-focus();
  @include button($btn__text-colour, $btn__text-colour--hover, $btn__background-colour, $btn__shadow-colour, $btn__background-colour--hover, $btn__shadow-colour--hover, $btn__background-colour--active);

  &--invert {
    @include button($link__colour, $link__colour, $white, $warm-grey-2, $warm-grey-1, $warm-grey-2, $warm-grey-2);
  }

  &--blue {
    @include button($btn__text-colour, $blue-cyan, $white, $link__colour, $link__colour, $blue-mid-dark-10, $blue-mid-dark-10);
  }

  &--blue-steel {
    @include button($btn__text-colour, $btn__text-colour--hover, $blue-steel, $blue-steel-dark-10, $blue-steel-dark-10, $black, $black);
  }

  &--blue-invert {
    @include button($link__colour, $white, $white, $blue-cyan, $blue-cyan, $link__colour, $blue-cyan);
  }

  &--green {
    @include button($white, $white, $green-access, $green-access-dark-10, $green-access-dark-10, $green-access-dark-20, $green-access-dark-20);
  }

  &--grey {
    @include button($link__colour, $link__colour, $warm-grey-2, $warm-grey-3, $white, $warm-grey-2, $white);

    &:active {
      box-shadow: 0px -4px 0px $warm-grey-2;
    }
  }
}

.button-icon-left {
  margin-left: -.4rem;
  margin-right: .8rem;

  @media screen and (min-width: map-get($breakpoints, md)) {
    margin-left: -.8rem;
  }
}

.button-icon-right {
  margin-left: .8rem;
  margin-right: -.4rem;
}

@media screen and (min-width: map-get($breakpoints, lg)) {
  .button--icon-right-hover {
    .button-icon-right {
      transition: $transition;
    }

    &:hover {
      .button-icon-right {
        margin-left: 1.2rem;
      }
    }
  }
}