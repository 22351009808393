//---------//
// Colours //
//---------//

// Brand colours
$white: #fff;
$black: #000;
$black-tint: #333;
$green-cap: #45A929;
$green-access: #45A929;
$green-access-dark-10: #34801F;
$green-access-dark-20: #235715;
$green-dark: #04624F;
$green-focus: #C2D600;
$blue-steel: #141464; // navy
$blue-steel-tint-10: #E7E7EF;
$blue-steel-tint-50: #8A8AB2;
$blue-steel-dark-10: #0B0B39;
$blue-mid: #0069BF; // cap blue
$blue-mid-dark-10: #004D8C;
$blue-mid-dark-20: #003159;
$blue-cyan: #009EE3;
$blue-light: #D9E6FC;
$blue-pale: #F3F7FF;
$warm-grey-1: #F5F5F5; // very light grey
$warm-grey-2: #E7E7EF; // navy tint 10 ($blue-steel-tint-10)
$warm-grey-3: #B3A7A1;
$red: #DE0030;
$pink: #BF0078;
$pink-dark-10: #8C0058;
$pink-dark-20: #590038;
$orange: #FF6600;
$yellow: #F5D600;

$error-red: #DE0030;

// Colour assignment
$primary-colour: $green-access;

// Accessibility focus state colours
$accessibility__highlight: $green-focus;
$accessibility__text: $black;
$accessibility__box-shadow: 0 4px $accessibility--text;

//--------//
// Layout //
//--------//

// Breakpoints
$breakpoints: (
  sm: 480px, // mobile
  md: 640px, // tablet
  lg: 960px, // tablet large
  xl: 1024px, // large
  xxl: 1208px,
  xxxl: 1392px
);

// Container
$container__max-width: 1296px;
$container__gutter--sm: 16px;
$container__gutter--md: 24px;
$container__gutter--lg: 32px;
$container__gutter--xl: 48px;
$container-inner-sm__max-width: 624px;
$container-inner__max-width: 784px;
$container-inner-lg__max-width: 880px; // hero quote
$container-inner-xl__max-width: 888px;
$container-inner-xxl__max-width: 992px; // hero title

// Matrix wider width
$matrix__wider-width: 916px;

// Vertical spacers
$vertical-spacers--mobile: (
  1: 0px,
  2: 4px,
  3: 8px,
  4: 12px,
  5: 16px,
  6: 20px,
  7: 24px,
  8: 32px,
  9: 40px,
  10: 48px
);

$vertical-spacers--desktop: (
  1: 4px,
  2: 8px,
  3: 12px,
  4: 16px,
  5: 20px,
  6: 24px,
  7: 32px,
  8: 40px,
  9: 48px,
  10: 64px
);

//------------//
// Typography //
//------------//

// Weights
$font-weight--normal: 400;
$font-weight--medium: 600;
$font-weight--semibold: 700;
$font-weight--bold: 800;
$font-weight--heavy: 900;

// Body - S
$body__font-family: 'Nunito Sans', sans-serif;
$body__font-colour: $black;
$body__font-weight: $font-weight--normal;
$body__font-size--mobile: 2rem;
$body__line-height--mobile: 2.8rem;
$body__font-size--desktop: 2.2rem;
$body__line-height--desktop: 3.2rem;

// Headings
$heading__font-family: 'Nunito Sans', sans-serif;
$heading__font-colour: $blue-steel;

// H1 - XXXL
$h1__colour: $heading__font-colour;
$h1__font-weight: $font-weight--heavy;
$h1__font-size--mobile: 4.4rem;
$h1__line-height--mobile: 4.4rem;
$h1__font-size--desktop: 7.2rem;
$h1__line-height--desktop: 6.8rem;

// H - XXL
$h-xxl__font-weight: $font-weight--heavy;
$h-xxl__font-size--mobile: 3.4rem;
$h-xxl__line-height--mobile: 3.6rem;
$h-xxl__font-size--desktop: 5.8rem;
$h-xxl__line-height--desktop: 6rem;

// H2 - XL
$h2__colour: $heading__font-colour;
$h2__font-weight: $font-weight--bold;
$h2__font-size--mobile: 3rem;
$h2__line-height--mobile: 4rem;
$h2__font-size--desktop: 3.6rem;
$h2__line-height--desktop: 4.4rem;

// H3 - L
$h3__colour: $heading__font-colour;
$h3__font-weight: $font-weight--bold;
$h3__font-size--mobile: 2.4rem;
$h3__line-height--mobile: 3.2rem;
$h3__font-size--desktop: 3rem;
$h3__line-height--desktop: 4rem;

// H - M
$h-md__font-weight: $font-weight--bold;
$h-md__font-size--mobile: 2.2rem;
$h-md__line-height--mobile: 2.8rem;
$h-md__font-size--desktop: 2.4rem;
$h-md__line-height--desktop: 3.2rem;

// H - S
$h-sm__font-weight: $font-weight--semibold;
$h-sm__font-size--mobile: 2rem;
$h-sm__line-height--mobile: 2.8rem;
$h-sm__font-size--desktop: 2.2rem;
$h-sm__line-height--desktop: 3.2rem;

// H - XS
$h-xs__font-weight: $font-weight--semibold;
$h-xs__font-size--mobile: 1.8rem;
$h-xs__line-height--mobile: 2.4rem;
$h-xs__font-size--desktop: 1.8rem;
$h-xs__line-height--desktop: 2.4rem;

// Body - XL
$body-xl__font-family: $heading__font-family;
$body-xl__font-weight: $font-weight--normal;
$body-xl__font-size--mobile: 2.8rem;
$body-xl__line-height--mobile: 3.2rem;
$body-xl__font-size--desktop: 3.8rem;
$body-xl__line-height--desktop: 4.4rem;

// Body - L
$body-lg__font-size--mobile: 2.4rem;
$body-lg__line-height--mobile: 3.2rem;
$body-lg__font-size--desktop: 3rem;
$body-lg__line-height--desktop: 4rem;

// Body - M
$body-md__font-size--mobile: 2.2rem;
$body-md__line-height--mobile: 2.8rem;
$body-md__font-size--desktop: 2.4rem;
$body-md__line-height--desktop: 3.2rem;

// Body - S
$body-sm__font-size--mobile: 2rem;
$body-sm__line-height--mobile: 2.8rem;
$body-sm__font-size--desktop: 2.2rem;
$body-sm__line-height--desktop: 3.2rem;

// Body - XS
$body-xs__font-size--mobile: 1.8rem;
$body-xs__line-height--mobile: 2.4rem;
$body-xs__font-size--desktop: 1.8rem;
$body-xs__line-height--desktop: 2.4rem;

// Standfirst
$stf__colour: $heading__font-colour;
$stf__font-weight: $font-weight--heavy;
$stf__font-size--mobile: $body-lg__font-size--mobile;
$stf__line-height--mobile: $body-lg__line-height--mobile;
$stf__font-size--desktop: $body-lg__font-size--desktop;
$stf__line-height--desktop: $body-lg__line-height--desktop;

// Quote
$quote__font-family: $heading__font-family;
$quote__font-weight: $font-weight--medium;
$quote__font-size--mobile: 2.4rem;
$quote__line-height--mobile: 3.2rem;
$quote__font-size--desktop: 3rem;
$quote__line-height--desktop: 4rem;

// Stats
$stat__colour: $blue-steel;
$stat__font-family: $heading__font-family;
$stat__font-weight: $font-weight--heavy;
$stat__font-size--mobile: 5.6rem;
$stat__line-height--mobile: 5.6rem;
$stat__font-size--desktop: 7.2rem;
$stat__line-height--desktop: 6.8rem;

// Links
$link__colour: $blue-mid;
$link__text-decoration: underline;
$link__text-decoration-thickness: 1px;
$link__text-underline-offset: 2px;

// general style
$border-radius: 0;

//---------//
// Buttons //
//---------//

$btn__background-colour: $pink;
$btn__shadow-colour: $pink-dark-10;
$btn__background-colour--hover: $pink-dark-10;
$btn__shadow-colour--hover: $pink-dark-20;
$btn__background-colour--active: $pink-dark-20;
$btn__text-colour: text-contrast($btn__background-colour);
$btn__text-colour--hover: text-contrast($btn__background-colour);
$btn__border-radius: 0;
$btn__padding: 1.3rem 2rem 1.1rem;
$btn__padding--desktop: 1.1rem 2.4rem 0.9rem;
$btn__padding--slim: .8rem 1.6rem .8rem;
$btn__padding--slim--tablet: 1.1rem 2.4rem 0.9rem;
$btn__padding--slim--desktop: 1.1rem 3.2rem 0.9rem;
$btn__font-weight: $font-weight--semibold;

//-------//
// Forms //
//-------//

$field-input__padding: .4rem .6rem;
$field-input__padding--focus: .3rem .5rem;
$field-input__padding--desktop: .2rem .6rem;
$field-input__padding--focus--desktop: .1rem .5rem;
$field-input__border: 2px solid $blue-steel;
$field-input__border-radius: 0;
$field-input__border--focus: 3px solid $accessibility__text;
$field-input__box-shadow--focus: 0 0 0 3px $accessibility__highlight;

//-------//
// Alert //
//-------//

$alert--primary__colour: $red;

//-------------------------//
// Animation & interaction //
//-------------------------//

$transition__duration: 0.2s;
$transition__curve: ease;
$transition: $transition__duration $transition__curve;

$hover__effect: darken; // darken or lighten
$hover__effect-amount: 10%;