$quiz-transition: all .5s ease-in-out .15s;

.fp-quiz {
  @include vertical-padding(8, bottom);
  background-color: $warm-grey-1;
  overflow: hidden;

  &__index-bar {
    position: relative;
    height: 16px;
    background-color: $blue-steel-tint-10;
    @include vertical-margin(9, bottom);
  }

  &__index-bar-progress {
    position: absolute;
    height: 100%;
    background-color: $blue-steel-tint-50;
    transition: $quiz-transition;
  }

  &__index {
    @include vertical-margin(3, bottom);
  }

  &__question {
    h2 {
      @include vertical-padding(5, bottom);
    }
  }

  &__options {
    input[type=radio] {
      display: none;

      &:checked + label {
        color: $white;
        background-color: $green-access;
      }
    }

    label {
      display: block;
      padding: 1.8rem 2.2rem 2.2rem;
      background-color: $white;
      height: 100%;
      margin: 0;
    }

    .option {
      @include vertical-margin(4, bottom);

      &:hover {
        label {
          box-shadow: inset 0 0 2px $green-access-dark-10;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    border-top: 2px solid $blue-steel-tint-10;
    @include vertical-margin(9, top);
  }

  &__sections {
    position: relative;
    margin-right: -40px;
    display: flex;
    align-items: flex-start; // for child elements' different heights
    transition: $quiz-transition; // for smooth height transition
  }

  &__section {
    flex: 0 0 calc(100% - 40px);
    position: relative;
    margin-left: 0;
    margin-right: 40px;
    opacity: 0;
    transition: $quiz-transition;
    pointer-events: none;

    &.active {
      margin-left: 0;
      opacity: 1;
      pointer-events: all;
    }

    &.checked {
      margin-left: -100%;
      opacity: 0;
      pointer-events: none;
    }
  }
}

.personality-icon-circle {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background-color: #FFF;
  padding: 24px;
  margin: 0 0 12px;
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .fp-quiz {
    &__options {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      label {
        padding: 2.2rem 2.6rem;
      }

      .option {
        flex: 0 0 calc(50% - 8px);
        @include vertical-margin(4, bottom);
      }
    }
  }

  .personality-icon-circle {
    width: 220px;
    height: 220px;
    padding: 32px;
    margin: 0;
  }
}

@media only screen and (min-width: map-get($breakpoints, lg)) {
  .personality-icon-circle {
    width: 280px;
    height: 280px;
    padding: 40px;
    margin: 0 0 0 auto;
  }
}