/** Base container **/
.kalendae {
	display: inline-block;
	zoom: 1;
	background: #eee;
	padding: 10px;
	margin: 5px;
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	font-size: 11px;
	font-family: 'Helvetica Neue', 'Helvetica';
	cursor: default;
	position: relative;
	.k-btn-close {
		position: absolute;
		top: -8px;
		right: -8px;
		width: 16px;
		height: 16px;
		background: white;
		border: 2px solid #ccc;
		color: #999;
		line-height: 17px;
		text-align: center;
		font-size: 13px;
		border-radius: 10px;
		box-shadow: 0 1px 3px rgba(0,0,0,0.75);
		cursor: pointer;
		text-decoration: none;
		&:after {
			content: "\2716";
		}
		&:hover {
			color: #7EA0E2;
			background: white;
			border-color: #7EA0E2;
		}
	}
	.k-calendar {
		display: inline-block;
		zoom: 1;
		width: 155px;
		vertical-align: top;
	}
	.k-separator {
		display: inline-block;
		zoom: 1;
		width: 2px;
		vertical-align: top;
		background: #ddd;
		height: 155px;
		margin: 0px 10px;
	}
	.k-title {
		text-align: center;
		white-space: nowrap;
		position: relative;
		height: 18px;
		width: 156px;
		display: block;
		overflow: hidden;
	}
	.k-caption {
		font-size: 12px;
		line-height: 18px;
	}
	.k-btn-previous-month {
		width: 16px;
		height: 16px;
		cursor: pointer;
		position: absolute;
		top: -3px;
		color: #777;
		font-size: 26px;
		line-height: 18px;
		font-weight: bold;
		font-family: arial;
		text-decoration: none;
		left: 16px;
		&:after {
			content: "\2039";
		}
		&:hover {
			color: #7EA0E2;
		}
	}
	.k-btn-next-month {
		width: 16px;
		height: 16px;
		cursor: pointer;
		position: absolute;
		top: -3px;
		color: #777;
		font-size: 26px;
		line-height: 18px;
		font-weight: bold;
		font-family: arial;
		text-decoration: none;
		right: 16px;
		&:after {
			content: "\203A";
		}
		&:hover {
			color: #7EA0E2;
		}
	}
	.k-btn-previous-year {
		width: 16px;
		height: 16px;
		cursor: pointer;
		position: absolute;
		top: -3px;
		color: #777;
		font-size: 26px;
		line-height: 18px;
		font-weight: bold;
		font-family: arial;
		text-decoration: none;
		left: 0;
		&:after {
			content: "\00AB";
		}
		&:hover {
			color: #6FDF81;
		}
	}
	.k-btn-next-year {
		width: 16px;
		height: 16px;
		cursor: pointer;
		position: absolute;
		top: -3px;
		color: #777;
		font-size: 26px;
		line-height: 18px;
		font-weight: bold;
		font-family: arial;
		text-decoration: none;
		right: 0;
		&:after {
			content: "\00BB";
		}
		&:hover {
			color: #6FDF81;
		}
	}
	.k-first-month {
		.k-btn-next-month {
			display: none;
		}
		.k-btn-next-year {
			display: none;
		}
	}
	.k-middle-month {
		.k-btn-next-month {
			display: none;
		}
		.k-btn-previous-month {
			display: none;
		}
		.k-btn-next-year {
			display: none;
		}
		.k-btn-previous-year {
			display: none;
		}
	}
	.k-last-month {
		.k-btn-previous-month {
			display: none;
		}
		.k-btn-previous-year {
			display: none;
		}
	}
	.k-title.k-disable-year-nav {
		.k-btn-next-year {
			display: none;
		}
		.k-btn-previous-year {
			display: none;
		}
		.k-btn-next-month {
			right: 0;
		}
		.k-btn-previous-month {
			left: 0;
		}
	}
	.k-header {
		width: 156px;
		display: block;
		overflow: hidden;
		span {
			float: left;
			margin: 1px 1px;
			text-align: center;
			font-weight: bold;
			width: 20px;
			padding: 1px 0;
			color: #666;
		}
	}
	.k-days {
		width: 156px;
		display: block;
		overflow: hidden;
		span {
			float: left;
			margin: 1px 1px;
			text-align: right;
			width: 13px;
			height: 1.1em;
			line-height: 1em;
			padding: 2px 3px 2px 2px;
			border: 1px solid transparent;
			border-radius: 3px;
			-moz-border-radius: 3px;
			-webkit-border-radius: 3px;
			color: #999;
		}
		span.k-in-month.k-active {
			border-color: #ddd;
			background-color: #fff;
			color: #333;
		}
		span.k-out-of-month {
			color: #ddd;
		}
		span.k-active {
			cursor: pointer;
			&:hover {
				border-color: #666;
			}
		}
		span.k-selected {
			border-color: #1072A5;
			color: #1072A5;
		}
		span.k-selected.k-active {
			background: #7EA0E2;
			color: white;
		}
		span.k-range {
			background: none;
			border-color: #6DD4FE;
		}
		span.k-range.k-in-month {
			background: #C4D4F1;
			border-color: #19AEFE;
			color: #333;
		}
	}
	.k-today {
		text-decoration: underline;
	}
	.k-week {
		&:hover {
			span.k-active {
				border-color: #666;
			}
		}
	}
}
.kalendae.k-floating {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 100000;
	margin: 0;
	box-shadow: 0 1px 3px rgba(0,0,0,0.75);
	-moz-box-shadow: 0 1px 3px rgba(0,0,0,0.75);
	-webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.75);
}
.kalendae.k-disable-next-month-btn {
	.k-btn-next-month {
		display: none;
	}
}
.kalendae.k-disable-previous-month-btn {
	.k-btn-previous-month {
		display: none;
	}
}
.kalendae.k-disable-next-year-btn {
	.k-btn-next-year {
		display: none;
	}
}
.kalendae.k-disable-previous-year-btn {
	.k-btn-previous-year {
		display: none;
	}
}
.clearfix {
	&:after {
		visibility: hidden;
		display: block;
		font-size: 0;
		content: " ";
		clear: both;
		height: 0;
	}
}

/** theme style container **/
html {
	.kalendae {
		background: #fff;
		border: 1px solid $warm-grey-3 !important;
		font-size: 100%;
		font-family: $body__font-family;
		border-radius: 0;
		padding: 1.25rem 1rem .75rem;
		box-shadow: 0 1px 3px rgb(0 0 0 / 25%);
		// overall size change 
		font-size: $body-xs__font-size--mobile;
		line-height: 1.125em;
		// testing purpose
		// display: block !important;

		.k-calendar {
			width: 14em;
			.k-header {
				span {
					width: calc(2em - 2px) !important;
					margin-left: 1px !important;
					margin-right: 1px !important;
					box-sizing: border-box !important;
					height: inherit;
					line-height: inherit;
					text-align: center;
					padding: 2px .25em 2px 2px;
					color: $blue-steel;
					margin: 5px auto;
				}
				width: 100%;
				height: auto;
				margin: 0;
				color: $blue-steel;
			}
			.k-days {
				span {
					width: calc(2em - 2px) !important;
					margin-left: 1px !important;
					margin-right: 1px !important;
					box-sizing: border-box !important;
					height: inherit;
					line-height: inherit;
					text-align: center;
					padding: 2px .25em 2px 2px;
				}
				width: 100%;
				height: auto;
				margin: 0;
				color: $blue-steel;
				span.k-in-month {
					color: $blue-steel;
					border-color: transparent;
					background-color: #fff;
					height: 30px;
					padding-top: 5px;
					margin-bottom: 5px;
				}
				span.k-in-month.k-active {
					color: #fff;
					background-color: $blue-cyan;
					height: 30px;
					padding-top: 5px;
					margin-bottom: 5px;
					&:hover {
						background-color: darken($blue-cyan, 10%);
					}
				}
				span.k-in-month.k-today {
					background-color: $blue-mid;
					text-decoration: none;
					&:hover {
						background-color: darken($blue-mid, 10%);
					}
				}
				span.k-out-of-month {
					color: $blue-steel;
					background-color: transparent;
					height: 30px;
					padding-top: 5px;
					margin-bottom: 5px;
					&:hover {
						color: $blue-steel-dark-10;
						border-color: transparent;
						background-color: rgba($blue-steel, 10%);
					}
				}
				span.k-selected.k-active {
					background: $blue-mid-dark-10;
					color: #fff;
					opacity: 1;
					&:hover {
						background-color: darken($blue-mid-dark-10, 10%);
					}
				}
			}
			.k-title {
				width: 100%;
				height: auto;
				margin: 0 0 .5rem;
				color: $blue-steel;
			}
			.k-btn-close {
				&:hover {
					color: #444;
					border-color: #fff;
				}
			}
			.k-caption {
				font-size: 100%;
				color: $blue-steel;
			}
			.k-btn-previous-month {
				color: $blue-steel;
				width: 16px;
				height: auto;
				font-size: 1.4em;
				line-height: 1em;
				font-weight: 400;
				&:hover {
					color: $blue-steel-dark-10;
					font-weight: 700;
				}
			}
			.k-btn-next-month {
				color: $blue-steel;
				height: auto;
				font-size: 1.4em;
				line-height: 1em;
				font-weight: 400;
				&:hover {
					color: $blue-steel-dark-10;
					font-weight: 700;
				}
			}
			.k-btn-previous-year {
				color: $blue-steel;
				height: auto;
				font-size: 1.4em;
				line-height: 1em;
				font-weight: 400;
				&:hover {
					color: $blue-steel-dark-10;
					font-weight: 700;
				}
			}
			.k-btn-next-year {
				color: $blue-steel;
				height: auto;
				font-size: 1.4em;
				line-height: 1em;
				font-weight: 400;
				&:hover {
					color: $blue-steel-dark-10;
					font-weight: 700;
				}
			}
		}

		.k-btn-close {
			position: absolute;
			top: -14px;
			right: -14px;
			width: 26px;
			height: 26px;
			border-radius: 50%;
			border: 2px solid $warm-grey-3;
			box-shadow: 0 1px 3px rgb(0 0 0 / 25%);

			&:before, 
			&:after {
				content: "";
				position: absolute;
				top: 3px;
				left: 10px;
				height: 16px;
				width: 2px;
				background-color: darken($warm-grey-3, 20%);
			}
			&:before {
				transform: rotate(45deg);
			}
			&:after {
				transform: rotate(-45deg);
			}

			&:hover {
				border-color: $blue-steel;

				&:before, 
				&:after {
					background-color: $blue-steel;
				}
			}
		}
	}
}
