button,
input,
optgroup,
select,
textarea {
  line-height: $body__line-height--mobile;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input[type="date"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
select,
textarea {
  display: inline-block;
  padding: $field-input__padding;
  border: $field-input__border;
  border-radius: $field-input__border-radius;
  background-color: $white;
  outline: none;
  width: 100%;

  &:focus {
    padding: $field-input__padding--focus;
    border: $field-input__border--focus;
    box-shadow: $field-input__box-shadow--focus;
  }

  &[aria-invalid="true"] {
    border-color: $error-red;
    border-width: 3px;
  }
}

.field-input--sm {
  max-width: 260px;
}

.field-input--md {
  max-width: 572px;
}

.form-choices {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-choice {
  display: flex;
  align-items: flex-start;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: .8rem;
  }
}

.form-choice__text {
  margin-top: .4rem;
  margin-left: 1.2rem;

  label {
    font-weight: 400;
    margin: 0;
  }

  .field-hint {
    margin: .4rem 0 0;
  }
}

input[type="radio"],
input[type="checkbox"] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  width: 4rem;
  height: 4rem;
  border: 2px solid $blue-steel;
  display: grid;
  place-content: center;
  flex-shrink: 0;

  &:before {
    content: "";
    display: block;
    transform: scale(0);
    transition: transform $transition;
  }

  &:checked:before {
    transform: scale(1);
  }

  &:focus-visible {
    outline: none;
    border: 3px solid $black;
    box-shadow: 0 0 0 3px $accessibility__highlight;
  }

  &:disabled {
    background-color: $blue-light;
    opacity: .5;
  }
}

input[type="radio"] {
  border-radius: 50%;

  &:before {
    width: 2rem;
    height: 2rem;
    background-color: $black;
    border-radius: 50%;
  }
}

input[type="checkbox"] {
  &:before {
    width: 2.3rem;
    height: 1.7rem;
    background-image: url("/dist/images/icons/check--black.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
}

.radio-label {
  display: flex;
  align-items: flex-start;
  position: relative;

  input[type="radio"] {
    margin-right: 1rem;
    flex-shrink: 0;
  }

  span {
    flex-grow: 1;
  }
}

.form-choices {
  .conditional-panel {
    display: none; // Reveal with js
    max-width: 554px;
    border-left: 4px solid $blue-steel;
    padding-left: 30px;
    margin-left: 18px;
    @include vertical-margin(7, bottom);
  }

  .field-row:last-of-type {
    margin-bottom: 0;
  }
}

// Custom dropdown
@supports (appearance: none) {
  select:not([multiple]) {
    appearance: none;
    padding-right: 50px;
    background-image: url("/dist/images/icons/custom-dropdown-arrow.svg");
    background-repeat: no-repeat;
    background-position: center right .8rem;
  }
}

label,
.legend-label {
  display: inline-block;
  color: $black;
  font-weight: $font-weight--medium;
  margin-bottom: .4rem;

  &.large {
    font-family: $heading__font-family;
  }

  & + .error-message {
    margin-top: -.8rem;
  }
}

.field-hint {
  display: block;
  // color: $blue-steel;
  margin-bottom: 1.2rem;
}

fieldset {
  padding: 0;
  margin: 0;
  border: none;
  appearance: none;
}

.vertical-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.field-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  @include vertical-margin(7, bottom);
}

.field-row--err {
  padding-left: 2rem;
  border-left: .4rem solid $error-red;
}

.form-field-error {
  color: $error-red;
  margin-bottom: 1.2rem;

  &:before {
    content: "";
    display: inline-block;
    width: 1.6rem;
    height: 1.6rem;
    margin-right: .6rem;
    background-image: url("/dist/images/icons/warning--red.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% auto;
  }
}

// freeform
.freeform-column {
  display: flex;
  flex-direction: column;

  button,
  .button {
    margin: 1rem auto 1rem 0;
  }
}

.freeform-input-only-label {
  position: relative;
  padding-left: 4rem;

  input[type=checkbox] {
    position: absolute;
    left: 0;
  }
}

.freeform-column--radio_group {
  label:not(.freeform-label) {
    position: relative;
    padding-left: 4rem;
  }

  input[type=radio] {
    position: absolute;
    left: 0;
  }

  .input-group-one-line {
    label {
      margin-right: 4rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.error-message {
  color: $alert--primary__colour;
  margin-bottom: 1.2rem;
}

.field-error {
  border-left: 4px solid $alert--primary__colour;
  padding-left: 1.6rem;
}

.ff-errors {
  color: $alert--primary__colour;
  margin: -1rem 0 1.4rem 0;
  padding-left: 0;
  list-style: none;

  li {
    margin-bottom: .8rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.form-button--primary {
  @include button-focus();
  @include button($white, $white, $green-access, $green-access-dark-10, $green-access-dark-10, $green-access-dark-20, $green-access-dark-20);
}

.form-button--secondary {
  @include button-focus();
  @include button($btn__text-colour, $btn__text-colour--hover, $blue-steel, $blue-steel-dark-10, $blue-steel-dark-10, $black, $black);
}

//------//
// > md //
//------//

@media screen and (min-width: map-get($breakpoints, md)) {
  button,
  input,
  optgroup,
  select,
  textarea {
    line-height: $body__line-height--desktop;
  }

  input[type="date"],
  input[type="email"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="url"],
  select {
    padding: $field-input__padding--desktop;

    &:focus {
      padding: $field-input__padding--focus--desktop;
    }
  }

  .field-error {
    padding-left: 2.4rem;
  }
}

.dynamic-form {
  position: relative;

  .register {
    margin-top: 0px !important;
  }
  
  .content {
    display: none;
  }

  [type="checkbox"] {
    width: 148px;
    height: 56px;
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    cursor: pointer;

    &:hover + .button--green {
      background-color: $green-access-dark-10;
      box-shadow: 0 4px #235715;
    }

    &:checked {
      height: 0px;
      width: 0px;
      pointer-events: none;
      visibility: hidden;
    }

    &:checked ~ .content {
      display: block;
    }

    &:checked + .register {
      display: none;
    }
  }

  .field-row label {
    color: $black;
    margin: 8px 0 4px;
  }

  [type="submit"] {
    margin-top: 20px;
  }
}