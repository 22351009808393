table {
  width: 100%;
  border-spacing: 0;
  @include vertical-margin(7, top);
  @include vertical-margin(8, bottom);
}

th {
  font-weight: $font-weight--semibold;
  text-align: left;
  border-bottom: 2px solid $blue-steel-tint-50;
}

td, th {
  padding: 1rem 1rem 1rem 0;
  vertical-align: top;
}

td {
  border-bottom: 2px solid $blue-steel-tint-10;
}
