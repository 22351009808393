.mc-field-group {
  @include vertical-margin(7, bottom);
  max-width: 572px;

  label {
    margin-bottom: 1.2rem;
  }

  input[type="date"],
  input[type="email"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="url"],
  select,
  textarea {
    display: block;
    margin-bottom: 0;
    width: 100%;
  }

}

#mc-embedded-subscribe {
  margin-top: calc(map-get($vertical-spacers--mobile, 8) - 1.2rem);
}

.indicates-required {
  color: $blue-steel;
  margin-bottom: 1.2rem;
}