.hero-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  object-fit: cover;
  height: 100%;
  width: 100%;

  &--sm {
    max-width: 888px;
  }

  &__caption {
    color: $blue-steel;
    margin-top: .8rem;
    padding-right: 4.8rem;
    @include vertical-margin(6, bottom);
  }
}

.hero-spacer {
  @include vertical-padding(6, top);
  @include vertical-padding(6, bottom);
  background-color: $white;

  &--lg {
    @include vertical-padding(7, top);
    @include vertical-padding(7, bottom);
  }

  &--no-padding {
    padding: 0;
  }
}

.hero-author {
  @include vertical-margin(6, top);

  &__headshot-asset {
    display: block;
    width: 70px;
    margin-top: .4rem;
    margin-bottom: 1.6rem;
  }

  &__name {
    float: left;
    margin-right: .6rem;
  }
}

.hero-divider {
  border: none;
    border-top: 2px solid $warm-grey-2;
    @include vertical-margin(5, top);
    margin-bottom: 0;
}

.site-hero {
  &__main {
    @include vertical-padding(6, top);
    @include vertical-padding(9, bottom);
    background-color: $warm-grey-1;

    .entry-info {
      @include list-reset();
      @include vertical-margin(6, top);
      @include vertical-margin(6, bottom);
      color: $blue-steel;

      &:last-child {
        margin-bottom: 0;
      }

      &__list {
        margin-bottom: .8rem;

        &:last-child {
          margin-bottom: 0;
        }

        &__icon {
          vertical-align: -1px;
        }
      }
    }
  }

  &__title {
    @include vertical-padding(6, top);
    @include vertical-padding(6, bottom);
    background-color: $warm-grey-1;
  }

  &__meta {
    // @include vertical-padding(6, top);
    @include vertical-padding(7, bottom);
    background-color: $warm-grey-1;

    .entry-meta {
      color: $blue-steel;

      .tags {
        display: inline-flex;
        margin-right: .4rem;
      }

      .post-date {
        vertical-align: -2px;
      }
    }

    hr {
      border: none;
      border-top: 2px solid $warm-grey-2;
      @include vertical-margin(6, top);
      margin-bottom: 0;
    }
  }

  &__image {
    position: relative;
    padding-top: 56.25%;
    min-height: 200px;
  }

  &__article-image {
    position: relative;
    background-color: $warm-grey-1;

    .hero-spacer {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
    }

    img {
      position: relative;
      display: block;
    }
  }

  &__info {
    @include vertical-padding(8, bottom);
  }

  &__quote {
    @include vertical-padding(9, top);
    @include vertical-padding(9, bottom);
    color: $white;
    background-color: $blue-steel;

    .quote-mark {
      display: inline-block;
      width: 22px;
      height: auto;
      vertical-align: -1px;
    }
  }

  &__spacer {
    background-color: $warm-grey-1;

    .container {
      padding-left: 0;
      padding-right: 0;
    }

    .container-inner {
      max-width: none;
    }

    &--transparent {
      background-color: transparent;

      .container-inner {
        position: relative;
      }

      .hero-spacer {
        position: absolute;
        width: 100%;
        bottom: 0;
      }
    }

    &--dark {
      background-color: $blue-steel;
    }

    &--white {
      background-color: $white;
    }
  }

  &__donate-widget {
    position: relative;

    .hero-image {
      position: relative;
      display: none;
    }
  }
}

.site-hero__donate-widget + .site-hero__spacer {
  .hero-spacer {
    border-top: 1px solid $warm-grey-2;
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .hero-author {
    &__headshot {
      flex: 0 0 calc(70px + 2.4rem);
    }
    
    &__headshot-asset {
      margin-bottom: .4rem;
    }
    
    &__with-headshot {
      display: flex;

      .hero-author__text {
        display: flex;
        align-items: center;
      }
    }
  }
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .site-hero {
    &__image {
      padding-top: 0;
      height: 360px;
    }

    &__quote .quote-mark {
      width: 29px;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, xl)) {
  .site-hero {
    &__image {
      height: 500px;
    }

    &__spacer {
      .container-inner {  
        &--lg {
          max-width: $container-inner-lg__max-width;
        }
      }
    }

    &__donate-widget {
      @include vertical-padding(9, top);
      @include vertical-padding(9, bottom);

      .hero-image {
        position: absolute;
        display: block;
      }
    }
  }

  .site-hero__donate-widget + .site-hero__spacer {
    .hero-spacer {
      border-top: none;
    }
  }
}