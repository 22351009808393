.help-options {
  @include vertical-margin(9, top);

  hr {
    border: none;
    border-top: 2px solid $warm-grey-2;
  }

  &__heading {
    @include vertical-margin(9, top);
    @include vertical-margin(8, bottom);
  }

  &__list {
    @include vertical-margin(10, top);
  }
}

@media screen and (min-width: map-get($breakpoints, xl)) {
  .help-options {
    .entry-card {
      padding: 2.5rem 3.7rem 2.9rem;

      &__heading {
        font-size: $h2__font-size--desktop;
        line-height: $h2__line-height--desktop;
      }
    }
  }
}