.icon-and-link {
  @include vertical-margin(9, top);
  @include vertical-margin(9, bottom);
  @include vertical-padding(7, bottom);
  border-bottom: 2px solid $blue-steel-tint-10;
  border-radius: $border-radius;
  // padding: 1.8rem 2.2rem;
  // background-color: $white;

  &__image {
    width: 7rem;
    height: 7rem;
    margin-bottom: .8rem;

    .icon {
      display: block;
      max-height: 100%;
      margin-top: .4rem;
      margin-bottom: .4rem;
    }
  }

  &__heading {
    color: $blue-steel;
    margin-bottom: .4rem;
  }

  &__link {
    font-weight: $font-weight--semibold;

    .arrow {
      vertical-align: -3px;
      margin-left: .4rem;
    }
  }
}

.matrix--icon-and-link + .matrix--icon-and-link {
  .icon-and-link {
    margin-top: map-get($vertical-spacers--mobile, 8) * -1;
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .icon-and-link {
    display: flex;
    // padding: 2.2rem;

    &__image {
      flex: 0 0 7rem;
      margin-right: 2.4rem;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, md)) {
  // .icon-and-link {
  //   padding: 2.2rem 2.6rem;
  // }

  .matrix--icon-and-link + .matrix--icon-and-link {
    .icon-and-link {
      margin-top: map-get($vertical-spacers--desktop, 8) * -1;
    }
  }
}