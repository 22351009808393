.newsletter-cta {
  color: $white;
  background-color: $primary-colour;
  @include vertical-padding(7, top);
  @include vertical-padding(8, bottom);

  &__image-asset {
    display: block;
    padding-top: .8rem;
    margin-bottom: 1.6rem;
  }

  &__heading {
    // color: $white;
    @include vertical-margin(4, bottom);
  }

  &__text {
    @include vertical-margin(6, bottom);
  }

  &__button {
    margin: 0;
  }

  &__link {
    margin-bottom: 4px;
  }

  &__image {
    max-width: 576px;
  }

  &__content {
    max-width: 576px;
  }
}

@media screen and (min-width: map-get($breakpoints, xl)) {
  .newsletter-cta {
    &__row {
      position: relative;
      display: flex;
      align-items: stretch;
    }

    &__image-asset {
      margin-bottom: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    &__image {
      position: relative;
      min-height: 100%;
      width: 368px;
      margin-right: 4.8rem;
    }
  }
}