.heading-and-rich-text {
  color: $white;
  
  &__heading {
    color: $white;
    @include vertical-margin(6, bottom);
  }
}

.matrix--heading-and-rich-text {
  @include vertical-padding(9, top);
  @include vertical-padding(9, bottom);
  background-color: $blue-steel;
}