.bnef-calc-hero {
  @include vertical-margin(8, bottom);

  h2 {
    display: flex;
    align-items: center;
  }
}

.bnef-calc-icon {
  color: $green-access;
  width: 32px;
  height: auto;
  margin-right: 8px;
}

.bnef-calc-cta {
  color: $white;
  background-color: $blue-steel;
  padding: $container__gutter--lg;

  h3 {
    color: $white;
    @include vertical-margin(3, bottom);
  }

  .button {
    margin: 0;
  }
}

.bnef-calc-cta-text {
  @include vertical-margin(6, bottom);
}

.bnef-calc-share {
  padding: $container__gutter--lg;
  height: 100%;
  border: 1px solid $green;

  h3 {
    @include vertical-margin(3, bottom);
  }

  .social-share {
    padding: 0;
    border: none;
  }
}

.bnef-amount-board {
  display: flex;
}

.bnef-amount {
  @include vertical-margin(3, bottom);
  color: $stat__colour;
  font-family: $stat__font-family;
  font-weight: $stat__font-weight;
  font-size: $stat__font-size--desktop;
  line-height: $stat__line-height--desktop;

  &.currency {
    margin-right: 12px;
  }
}

.bnef-amount-panel {
  letter-spacing: 8px;

  &--desktop {
    display: none;
  }
}

.bnef-calc {
  @include vertical-margin(9, top);
  @include vertical-margin(10, bottom);
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .bnef-calc-icon {
    width: 40px;
    margin-right: 12px;
  }

  .bnef-amount-panel {
    letter-spacing: 8px;
  
    &--mobile {
      display: none;
    }
    
    &--desktop {
      display: block;
    }
  }
}