#donateForm {
  .form-page:not(:first-of-type) {
    display: none;
  }

  .form-page--hidden {
    display: none;
  }

  #manualAddress {
    display: none;
  }

  .gift-aid-logo {
    display: inline-block;
    @include vertical-margin(8, bottom);
  }

  .direct-debit-logo {
    display: inline-block;
    @include vertical-margin(7, bottom);
  }

  #firstPaymentDateFieldWrapper {
    width: 100%;
    max-width: 444px;
    display: flex;
    flex-direction: column;
  }

  .required-field {
    &:after {
      content: "*";
      color: #e30031;
      margin-left: 0.3rem;
    }
  }
}

.fieldset-hidden {
  display: none;
}

.payment-method {
  width: 100%;
  display: flex;
  flex-direction: column;

  label {
    margin: 0 0 1.6rem;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1.6rem;
    background-color: $white;
    font-weight: 400;
    // border-radius: .8rem;
    border: 2px solid $blue-steel;
    cursor: pointer;

    span {
      flex-grow: 1;
      margin-top: .4rem;
    }

    > svg {
      flex-shrink: 0;
      margin-left: 1.2rem;
      opacity: 0;
      transition: opacity $transition;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .payment-method__icon {
    width: 64px;
    flex-shrink: 0;
    margin-right: 1.2rem;
    display: flex;
    justify-content: center;

    svg {
      display: block;
      height: 100%;
      width: auto;
    }
  }

  input:checked + label {
    border-color: $primary-colour;
    box-shadow: 0 0 0 1px $primary-colour;

    > svg {
      opacity: 1;
    }
  }
}

.donate-form-summary {
  padding: 16px;
  background: $blue-steel;
  color: $white;

  #donation-summary-type, #donation-summary-amount {
    font-weight: $font-weight--heavy;
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  #donateForm {
    #firstPaymentDateFieldWrapper {
      flex-direction: row;

      .field-row {
        margin: 0;
        flex-grow: 1;
      }

      .field-row:first-child {
        width: 156px;
        flex-shrink: 0;
        margin-right: 34px;
      }
    }
  }
}