.desktop-nav-screen {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0,.5);
  //z-index: -1;
}

.site-header {
  width: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
  transition: top .4s;

  &--box-shadow {
    box-shadow: 0 2px 4px rgba(0,0,0,.1);
  }
  
  a {
    color: $link__colour;
  }
  
  &__top {
    position: relative;
    @include vertical-padding(5, top);
    @include vertical-padding(5, bottom);
    background-color: $white;
    z-index: 1111;

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__bottom {
    display: none;
    color: $blue-steel-tint-10;
    background-color: $blue-steel;
    height: calc(100vh - 80px);
    padding-bottom: 80px;
    overflow: auto;
    z-index: 1111;

    hr {
      display: none;
    }
  }
}

.site-logo {
  display: block;

  &--mobile {
    display: none;
  }

  &--desktop {
    display: none;
  }
}

.direct-navs {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  background-color: $warm-grey-1;

  &__ctas {
    margin-bottom: 2rem;

    a {
      color: $white;
    }
  
    .button {
      margin: 0 1rem 0 0;
  
      &:last-child {
        margin-right: 0;
      }
    }

    &--header-top {
      margin-bottom: 0;
      margin-right: 1.2rem;
      margin-left: auto;
      padding-bottom: 4px;

      .direct-navs__get-help {
        display: none;
      }
    }

    &--header-bottom {
      .direct-navs__donate {
        display: none;
      }
    }
  }
}

.burger-menu {
  position: relative;
  width: 18px;
  height: 18px;
  margin-left: .9rem;

  &__bar {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $link__colour;

    &--1 {
      top: 0;
    }

    &--2 {
      top: 50%;
      margin-top: -1px;
    }

    &--3 {
      bottom: 0;
    }
  }
}

.mobile-nav-button {
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  color: $link__colour;

  &.active {
    .burger-menu__bar {
      top: 50%;
      margin-top: -1px;

      &--1 {
        transform: rotate(45deg);
      }
  
      &--2 {
        opacity: 0;
      }
  
      &--3 {
        transform: rotate(-45deg);
      }
    }
  }
}

.main-nav-list {
  @include list-reset();

  a, 
  .menu-tab {
    display: inline-block;
    color: $white;
  }

  a {
    font-weight: $font-weight--bold;
  }
  
  .menu-tab {
    font-weight: $font-weight--medium;
    position: relative;
    padding-right: 26px;
    cursor: pointer;
    
    &__icon {
      position: absolute;
      top: 50%;
      margin-top: -5px;
      right: 0;
    }

    // &:hover {
    //   color: $accessibility__text;
    //   text-decoration: $link__text-decoration;
    //   text-decoration-thickness: $link__text-decoration-thickness;
    //   text-underline-offset: $link__text-underline-offset;
    // }
    
    &.active {
      .menu-tab__icon {
        transform: rotate(180deg);
      }
    }
  }

  .menu-item__icon {
    margin-left: 2px;
    vertical-align: -2px;
  }
  
  .nav-icon {
    display: block;
    width: 4.8rem;
    height: 4.8rem;
    margin-bottom: .8rem;
  }

  &__level-0 {
    padding-left: $container__gutter--sm;
    padding-right: 1.6rem;
    
    .menu-tab {
      display: block;
      padding-top: 1.6rem;
      padding-bottom: 1.6rem;
    }
    
    & > li {
      border-bottom: 2px solid rgba($white, .3);

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &__level-1 {
    & > li {
      padding-bottom: 2.4rem;

      & > a {
        font-size: $h3__font-size--mobile;
        line-height: $h3__line-height--mobile;
        @include vertical-margin(3, bottom);

        & > .menu-item__icon {
          width: 15px;
          height: auto;
          margin-left: 4px;
          vertical-align: -4px;
        }
      }

      & > .excerpt {
        font-weight: $font-weight--semibold;
      }
    }
  }

  &__level-2 {
    @include vertical-margin(7, top);
    padding-left: 1.6rem;
    border-left: 4px solid rgba($white, .3);

    & > li {
      padding-bottom: 1.6rem;

      & > a {
        font-weight: $font-weight--semibold;
        margin-bottom: .4rem;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

//-----------------------------//
// sticky donation button and form //
//-----------------------------//

body.freezePage {
  overflow: hidden;
}

.header-sticky-donation-form-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: 2;
  max-width: 500px;
  width: 100%;
  height: 0;

  .donate-widget__tabs {
    padding: 0;
  }

  .donate-widget__tabs__tabs {
    margin-bottom: 20px;
  }

  .donate-widget__option + .donate-widget__option {
    .donate-widget__option-label {
      margin-left: 3.3%;
    }
  }

  .donate-widget__option-radio {
    visibility: hidden;
    height: 0;
    width: 0;
    position: absolute;
  }

  .donate-widget__option-label {
    float: left;
    max-width: 31.1%;
    width: 100%;  
    padding: 1.6rem 1rem;

    .donate-widget__option-amount {
      flex: 0;
      font-size: 3rem;
      line-height: 3.6rem;
      margin: 0 auto;
    }
  }
}

.header-sticky-donation-form {
  background: $white;
  border-radius: 0 0 0.8rem 0.8rem;
  box-shadow: 5px 5px 8px 0px rgba(51,51,51,0.3);
  padding: 15px;
  position: relative;
  transition: .5s ease-in-out;
  top: -600px;
  pointer-events: none;

  &.active {
    top: 0;
    pointer-events: all;
  }

  .single-amount-description, .monthly-amount-description {
    display: none;

    &.active {
      display: block;
    }
  }
}

.header--show {
  .header-sticky-donation-form-trigger.visible, #header-sticky-donation-form.active {
    top: 120px;
    @media screen and (min-width: map-get($breakpoints, lg)) {
      top: 206px;
    }
  }
}

.header--show {
  #informationNotice.active ~ .header-sticky-donation-form-container :is(#header-sticky-donation-form.active, .header-sticky-donation-form-trigger.visible) {
    top: 200px;
    @media screen and (min-width: map-get($breakpoints, lg)) {
      top: 286px;
    }
  }
}

.header-sticky-donation-form-trigger {
  width: 100%;
  margin: 0;
  z-index: 3;
  position: relative;
  top: 0;
  transition: all .4s;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  display: flex;
  transform: translateY(-120%);
  opacity: 0;

  &.visible {
    transform: translateY(0);
    opacity: 1;
  }

  &.active {
    svg {
      transform: rotate(180deg);
    }
  }
}

.header-sticky-donation-form-container-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba($black, 0.5);
  top: 0;
  left: 0;
  display: block;
  opacity: 0;
  pointer-events: none;

  &.overlay {
    opacity: 1;
    pointer-events: all;
  }
}

@media screen and (min-width: 380px) {
  .site-logo {
    &--xs {
      display: none;
    }
  
    &--mobile {
      display: block;
    }
  }
}

//------//
// > sm //
//------//

@media screen and (min-width: map-get($breakpoints, sm)) {
  .direct-navs {
    & > .container {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    &__ctas {
      margin-bottom: 0;
    }
  }

  .main-nav-list {
    .nav-icon {
      position: absolute;
      left: 0;
    }

    &__level-0 {
      padding-left: $container__gutter--md;
      padding-right: $container__gutter--md;
    }

    &__level-1 {
      & > li {  
        position: relative;

        & > a, 
        & > .excerpt {
          margin-left: 7.2rem;
        }
      }
    }
  }
}

@media screen and (min-width: 540px) {
  .direct-navs {
    &__get-help--mobile {
      display: none;
    }

    &__ctas {
      &--header-top {
        .direct-navs__get-help--tablet {
          display: inline-block;
        }
      }
    }
  }
}

//------//
// > md //
//------//

@media screen and (min-width: map-get($breakpoints, md)) {
  .site-logo {
    &--mobile {
      display: none;
    }

    &--desktop {
      display: block;
      width: 80px;
      height: auto;
    }
  }
  
  .direct-navs {
    &__donate--mobile {
      display: none;
    }

    &__ctas {
      margin-bottom: 0;

      &--header-top {
        margin-right: 1rem;

        .direct-navs__donate--tablet {
          display: inline-block;
        }
      }
    }
  }

  .main-nav-list {
    .nav-icon {
      top: .6rem;
      width: 12rem;
      height: auto;
    }

    &__level-0 {
      padding-left: $container__gutter--lg;
      padding-right: $container__gutter--lg;

      .menu-tab {
        padding-top: 2.4rem;
        padding-bottom: 2.4rem;
      }
    }

    &__level-1 {
      & > li {  
        padding-bottom: 1.6rem;

        & > a, 
        & > .excerpt,
        & > form {
          margin-left: 14.4rem;
        }

        & > a {
          font-size: $h3__font-size--desktop;
          line-height: $h3__line-height--desktop;
        }
      }
    }

    &__level-2 {
      padding-left: 0;
      border-left: none;
      padding-top: 2.8rem;
      border-top: 2px solid rgba($white, .3);
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      & > li {
        flex: 0 0 calc(50% - 1.2rem);
      }
    }
  }

  .header-sticky-donation-form {
    .donate-widget__option-amount {
      font-size: 4rem;
      line-height: 4.8rem;
    }
  }
}

//------//
// > lg //
//------//

@media screen and (min-width: map-get($breakpoints, lg)) {
  .desktop-nav-screen {
    display: block;
    top: -99999px;
    left: -99999px;
    right: 99999px;
    transform: translateY(2rem);
    opacity: 0;
    transition: opacity $transition__duration ease-in-out, transform $transition__duration ease-in-out, top 0s $transition__duration, left 0s $transition__duration, right 0s $transition__duration;

    &.show {
      top: 66px; // menu-tab height
      left: 0;
      right: 0;
      transform: translateY(0);
      opacity: 1;
      transition: opacity $transition__duration ease-in-out, transform $transition__duration ease-in-out;
    }
  }

  .site-logo {
    &--desktop {
      width: 100px;
    }
  }

  .mobile-nav-button {
    display: none;
  }

  .direct-navs {
    display: none;

    &__ctas--header-top {
      margin-left: 1.6rem;
      margin-right: 0;
    }
  }

  .site-header__bottom {
    position: relative;
    z-index: 3;
    display: block;
    background-color: $white;
    height: auto;
    padding-bottom: 0;
    overflow: visible;

    hr {
      display: block;
      margin: 0;
      border: none;
      border-top: 2px solid $blue-steel-tint-10;
    }
  }

  .main-nav-list {
    background-color: $blue-steel;

    .nav-icon {
      //top: 3.8rem;
      left: $container__gutter--xl;
    }

    &__level-0 {
      display: flex;
      background-color: $white;
      padding-left: $container__gutter--xl;
      padding-right: $container__gutter--xl;
      max-width: $container__max-width;
      margin-left: auto;
      margin-right: auto;

      .menu-tab {
        color: $link__colour;
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
        margin-right: 2.4rem;

        &:hover {
          color: $accessibility__text;
          text-decoration: $link__text-decoration;
          text-decoration-thickness: $link__text-decoration-thickness;
          text-underline-offset: $link__text-underline-offset;
        }

        &--active {
          box-shadow: inset 0 -.6rem 0 $warm-grey-2;
        }

        &.active {
          box-shadow: inset 0 -.6rem 0 $blue-steel;
        }
      }

      & > li {
        border-bottom: none;
      }
    }

    &__level-1 {
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;

      & > li {
        max-width: $container__max-width;
        padding-left: $container__gutter--xl;
        padding-right: $container__gutter--xl;
        padding-bottom: 3.2rem;
        margin-top: 3.2rem;
        margin-left: auto;
        margin-right: auto;

        & > a, 
        & > .excerpt {
          max-width: 576px;
        }
      }

      hr {
        margin-top: 32px;
        border-top: 2px solid rgba(255,255,255,.3);
      }
    }

    &__level-2 {
      margin-top: 0; 
      margin-bottom: -1.6rem;
      border-top: 0;

      &.level-2-with-excerpt {
        max-width: 728px;
      }
    }
  }

  .header-sticky-donation-form {
    padding: 20px 30px;
  }
}

@media screen and (min-width: map-get($breakpoints, xxl)) {
  .main-nav-list {
    &__level-2 {
      justify-content: flex-start;
      margin-right: -1.6rem;

      // 3 columns in desktop
      & > li {
        flex: 0 0 33.333%;
        padding-right: 1.6rem;
      }

      &.level-2-with-excerpt {
        justify-content: space-between;
        margin-right: 0;

        // keep 2 columns in desktop
        & > li {
          flex: 0 0 calc(50% - 1.6rem);
          padding-right: 0;
        }
      }
    }
  }
}