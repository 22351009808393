.entry-card-large {
  // color: $blue-steel;
  border: 2px solid $primary-colour;
  border-radius: $border-radius;
  background-color: $white;
  cursor: pointer;

  &__image-asset {
    display: block;
    // border-radius: .6rem .6rem 0 0;
  }

  &__heading {
    @include vertical-margin(4, bottom);
  }

  &__heading-icon {
    display: inline-block;
    width: 1.5rem;
    height: auto;
    vertical-align: -2px;
  }

  &__content {
    padding: 1.8rem 2.2rem 2.2rem;
  }

  &:hover {
    border-color: $green-access-dark-10;

    .entry-card-large__heading {
      a {
        color: $accessibility__text;
        text-decoration: $link__text-decoration;
        text-decoration-thickness: $link__text-decoration-thickness;
        text-underline-offset: $link__text-underline-offset;
      }
    }
  }

  &:active {
    border-color: $accessibility__text;

    .entry-card-large__heading {
      a {
        color: $accessibility__text;
        text-decoration: none;
        background-color: $accessibility__highlight;
        box-shadow: $accessibility__box-shadow;
      }
    }
  }
}

.matrix--entry-card-large {
  @include vertical-padding(9, top);
  @include vertical-padding(9, bottom);
}

@media screen and (min-width: map-get($breakpoints, xl)) {
  .entry-card-large {
    display: flex;

    &__image {
      flex: 0 0 50%;
      position: relative;
      padding-top: 28.125%;
    }

    &__image-asset {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      object-fit: cover;
      height: 100%;
      width: 100%;
      // border-radius: .6rem 0 0 .6rem;
    }

    &__content {
      flex: 0 0 50%;
      padding: 3rem 3.8rem 3rem 4rem;
    }
  }
}