.get-help-postcode-form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  .postcode-field {
    display: flex;
    flex-direction: column;
  }

  input {
    margin-right: auto;
    margin-bottom: 0;
  }

  input[type=text] {
    max-width: 512px;
    flex: 1;
  }

  input[type=submit] {
    font-weight: $font-weight--semibold;
    margin-bottom: 4px;
  }
}



.postcode-inputs--inline {
  display: inline-flex;

  input[type=text] {
    border-right: none;
    margin: 0;
    padding-left: 1.4rem;
    // border-top-left-radius: .4rem;
    // border-bottom-left-radius: .4rem;
  }

  .input--invert {
    color: $white;
    border: 2px solid $primary-colour;
    background-color: transparent;

    &::placeholder {
      color: $white;
    }
  }

  input[type=submit] {
    margin: 0 0 4px 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.postcode-field--invert {
  &.field-error {
    color: $white;
    border-color: $white;

    .error-message {
      color: $white;
    }
  }
}

.cta {
  .postcode-inputs--inline {
    input[type=text] {
      max-width: 262px;
    }
  }
}