.custom-carousel {
   margin-top: 4rem;
   position: relative;
   padding-left: 4rem !important;
   padding-right: 4rem !important;

   .swiper-slide {
      padding-bottom: 5rem !important;
   }

   @media screen and (max-width: 480px) {
      .prev-btn {
         left: -0.75rem !important;
      }

      .next-btn {
         right: -0.75rem !important;
      }
   }

   .prev-btn {
      left: 0;
   }

   .next-btn {
      right: 0;
   }

   .prev-btn,
   .next-btn {
      background: transparent;
      border: none;
      position: absolute;
      top: 50%;
      margin-top: -3rem;
      padding: 0;
      transform: translateY(-50%);
      height: 40px !important;
      width: 40px !important;
      background-image: none !important;
      color: #0069BF;
      
      svg {
         height: 4rem;
         width: 4rem;
         display: block;
      }

      &:hover {
         color: #004D8C;
      }

      &:active {
         color: #003159;
      }

      &:focus-visible {
         outline: none;
         background-color: #B3F261;
         color: #000000;
      }
   }

   .swiper-pagination-bullet {
      height: 24px;
      width: 24px;
      background-color: transparent !important;
      opacity: 1 !important;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      margin: 0 !important;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='11.9941' r='5' stroke='%230069BF' stroke-width='2'/%3E%3C/svg%3E%0A");

      &:hover {
         background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='11.9941' r='5' stroke='%23004D8C' stroke-width='2'/%3E%3C/svg%3E%0A");
      }

      &:active {
         background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='11.9941' r='5' stroke='%23003159' stroke-width='2'/%3E%3C/svg%3E%0A");
      }

      &:focus {
         outline: none;
         background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d_5909_26912)'%3E%3Ccircle cx='12' cy='11.9941' r='5' stroke='black' stroke-width='2'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d_5909_26912' x='3' y='2.99414' width='18' height='18' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeMorphology radius='3' operator='dilate' in='SourceAlpha' result='effect1_dropShadow_5909_26912'/%3E%3CfeOffset/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0.701961 0 0 0 0 0.94902 0 0 0 0 0.380392 0 0 0 1 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_5909_26912'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_5909_26912' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
      }

      &.swiper-pagination-bullet-active {
         background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='11.9941' r='5' fill='%23141464' stroke='%23141464' stroke-width='2'/%3E%3C/svg%3E%0A");
      }
   }

   .image-container {
      aspect-ratio: 16/9;
      overflow: hidden;
   }
   
   .image-container img {
      position: static;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
   }
}
