.cta {
  color: $white;

  &__image {
    position: relative;
  }

  &__image-asset {
    position: relative;
    display: block;
  }

  &__image-background {
    position: absolute;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    height: 6.4rem;
    background-color: $blue-steel;
  }

  &__heading {
    color: $white;
    @include vertical-margin(7, bottom);
  }

  &__text {
    @include vertical-margin(8, bottom);
  }

  &__button {
    margin-top: 0;
    @include vertical-margin(8, bottom);
  }

  &__content {
    position: relative;
    @include vertical-padding(9, top);
    @include vertical-padding(10, bottom);
    background-color: $blue-steel;
  }
}

.site-content__body:nth-child(even) + .cta {
  .cta__image {
    background-color: $warm-grey-1;
  }
}

// .matrix--call-to-action {
//   padding: 6.4rem 0 16rem;
//   background: white;
// }

// @media screen and (min-width: map-get($breakpoints, xl)) {
//   .matrix--call-to-action {
//     padding: 6.4rem 0 36rem;
//     background: white;
//   }
// }