.site-content__body {
  @include vertical-padding(9, top);
  @include vertical-padding(10, bottom);

  &.pb-0 {
    padding-bottom: 0;
  }

  & + .related-entries {
    background-color: $warm-grey-1;
  }
 
  &:nth-child(even) {
    background-color: $warm-grey-1;

    & + .related-entries {
      background-color: $white;
    }
  }

  &:first-child {
    padding-top: 0;
  }

  &--warm-grey-1 {
    background-color: $warm-grey-1;

    & + .related-entries {
      background-color: $white;
    }

    &:nth-child(even) {
      background-color: $white;

      & + .related-entries {
        background-color: $warm-grey-1;
      }
    }
  }

  .matrix:first-child > .container > .container-inner > * {
    margin-top: 0;
  }

  .matrix:last-child > .container > .container-inner > * {
    margin-bottom: 0;
  }
}

.site-content__body-sections {
  .matrix {
    &:not(.matrix--fixed-bg-color):nth-child(even) {
      background-color: $warm-grey-1;
    }
  }

  .matrix--light-bg-color + .matrix:not(.matrix--fixed-bg-color) {
    border-top: 1px solid $warm-grey-2;
  }

  .matrix--dark-bg-color + .matrix--dark-bg-color {
    border-top: 1px solid rgba($white, .3);
  }

  .matrix--dark-bg-color:last-child {
    border-bottom: 1px solid rgba($white, .3);
  }
}