.breadcrumb {
  @include vertical-margin(6, bottom);
  color: $green-access;

  ol {
    @include list-reset();
    display: flex;
    flex-wrap: wrap;
  }

  li {
    margin-right: .8rem;
  }

  a {
    margin-right: .4rem;
    cursor: pointer;
  }

  &__chevron {
    width: .65rem;
    height: 1.1rem;
  }
}

// For back links (where the svg is the first child of the li) flip the svg
.breadcrumb__chevron:first-child {
  transform: rotate(180deg);
  margin-right: .4rem;
}