.block-quote {
  color: $blue-steel;
  @include vertical-margin(9, top);
  @include vertical-margin(9, bottom);

  &__image {
    width: 22.4rem;
    margin-top: .8rem;
    margin-bottom: 1.6rem;

    img {
      display: block;
    }
  }

  .quote-source {
    color: $body__font-colour;
  }
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .block-quote {
    &--with-image {
      display: flex;
      
      .quote-source {
        max-width: 540px;
      }
    }

    &__image {
      flex: 0 0 22.4rem;
      margin-right: 3.2rem;
      margin-bottom: .8rem;
    }

    .blockquote {
      max-width: $container-inner__max-width;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, lg)) {
  .block-quote__quote {
    display: flex;
    align-items: center;
  }
}

@media screen and (min-width: map-get($breakpoints, xl)) {
  .block-quote {
    &__image {
      flex: 0 0 26.4rem;
      margin-right: 4.8rem;
    }
  }
}