.multi-links {
  border: 2px solid $primary-colour;
  padding: 1.8rem 2.2rem 2.2rem;
  border-radius: $border-radius;
  background-color: $white;
  @include vertical-margin(9, top);
  @include vertical-margin(9, bottom);

  &__heading {
    color: $black;
  }

  &__content {
    display: flex;
  }

  &__icon {
    .icon {
      display: block;
      max-width: 7rem;
      max-height: 7rem;
      margin-right: 2.4rem;
    }
  }

  &__links {
    @include list-reset();
  }

  &__link {
    font-weight: $font-weight--semibold;
    margin-bottom: .8rem;
  }

  &__link-arrow {
    margin-left: .4rem;
    vertical-align: -2px;
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .multi-links {
    &__links {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    &__link {
      margin-right: 2.4rem;
      margin-top: .4rem;
      margin-bottom: .4rem;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .multi-links {
    padding: 2.2rem 2.6rem 2.6rem;
  }
}