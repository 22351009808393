.person {
  @include vertical-margin(9, top);
  @include vertical-margin(9, bottom);
  @include vertical-padding(4, top);

  &__image {
    margin-bottom: 1.2rem;
  }

  &__headshot {
    display: block;
    width: 120px;
    margin-top: .4rem;
    margin-bottom: .4rem;
  }
}

.accordion__person {
  margin-top: 0;
  margin-bottom: 0;
  border: none;
  @include vertical-padding(4, bottom);

  .accordion__arrow {
    left: 0;
    top: 1.1rem;
  }

  .accordion__title-tag {
    font-weight: $font-weight--heavy;
    margin-top: .2rem;
  }

  .accordion__trigger {
    padding: 0 0 0 2.8rem;
  }

  .accordion__body {
    @include vertical-padding(6, top);
    @include vertical-margin(2, bottom);

    *:last-child {
      margin-bottom: 0;
    }
  }

  &.expanded {
    .accordion__arrow {
      top: 1rem;
    }
  }
}

.matrix + .matrix--person {
  .person {
    border-top: none;
  }
}

.matrix--person + .matrix--person {
  .person {
    border-top: 2px solid $warm-grey-2;
    margin-top: map-get($vertical-spacers--mobile, 9) * -1;
  }
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .person {
    &__headshot {
      width: 160px;
      height: auto;
    }
  }

  .matrix--person + .matrix--person {
    .person {
      margin-top: map-get($vertical-spacers--desktop, 9) * -1;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, lg)) {
  .person {
    display: flex;

    &__image {
      flex: 0 0 16rem;
      margin-right: 2.4rem;
      margin-bottom: 1.6rem;
    }
  }

  .accordion__person {
    flex: 0 0 calc(100% - 18.4rem);
    padding-top: 5.5rem;
    transition: all $transition;

    &.expanded {
      padding-top: 0;
    }
  }
}