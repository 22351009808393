#informationNotice {
    position: relative;
    z-index: 3;
    background-color: $yellow;
    height: 68px;
    display: flex;
    align-items: center;
    @media screen and (min-width: map-get($breakpoints, md)) {
        height: 80px;
    }
    .container {
        display: flex;
        align-items: center;
    }
    p {
        margin-bottom: 0;
        margin-right: 1.6rem;
        flex-grow: 1;
    }
    svg {
        fill: $black;
        height: auto;
        &.info {
            margin-right: 1.6rem;
            width: 2rem;
            flex-shrink: 0; // Stop the nested icon from shrinking
        }
        &.information-notice-close__icon {
            width: 1.8rem;
        }
    }
    a.information-notice-close {
        color: $black;
        flex-shrink: 0; // Stop the nested link from shrinking
    }
}