.language-toggle {
  display: flex;
  align-items: center;
  color: $blue-steel;

  &__option {
    &--selected {
      font-weight: $font-weight--semibold;
    }
  }

  &__option-icon {
    margin-right: .8rem;
  }

  &__option-divider {
    color: $blue-steel-tint-50;
    margin-left: .8rem;
    margin-right: .8rem;
  }
}