@media print {
  html {
    font-size: 50%;
  }

  .site-header {
    position: relative !important;
    top: 0 !important;
  }

  .site-header--box-shadow {
    box-shadow: none !important;
  }

  main {
    padding-top: 0 !important;
  }

  .mobile-nav-button,
  .language-toggle,
  .site-hero__spacer,
  .contact-and-language,
  .footer-nav,
  .footer-logos,
  .related-entries {
    display: none;
  }

  .site-hero__main,
  .site-hero__quote {
    background-color: transparent;
  }

  .site-hero__image {
    max-width: $container__max-width;
    margin: 0 auto;
  }

  .site-hero__quote {
    color: inherit;
  }

  .accordion__body {
    display: block;
  }

  .site-content__body {
    padding-top: 0;
    &:nth-child(even) {
      background-color: transparent;
    }
  }

  .site-footer {
    background-color: transparent;
    color: inherit;
  }

  .rich-text ul li:before {
    background-color: $black;
  }
}
