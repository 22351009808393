.site-footer {
  color: $white;
  background-color: $blue-steel;
  @include vertical-padding(7, top);
  @include vertical-padding(7, bottom);

  a {
    color: inherit;
    text-decoration: $link__text-decoration;
    text-decoration-thickness: $link__text-decoration-thickness;
    text-underline-offset: $link__text-underline-offset;
  }
}

.contact-and-language {
  @include vertical-margin(7, bottom);

  .custom-select {
    width: 100%;
    position: relative;
    max-width: 264px;
    margin-bottom: 0;
    @include vertical-margin(5, top);

    select {
      padding: 6px 40px;
      width: 100%;
      appearance: none;
      margin: 0;
      border: none;
    }

    select::-ms-expand {
      display: none;
    }
  
    &:after {
      content: "";
      width: 40px;
      height: 24px;
      border-left: 1px solid $warm-grey-3;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("/dist/images/icons/dropdown.svg");
      pointer-events: none;
    }

    // Translate Icon
    &:before {
      content: "";
      width: 24px;
      height: 24px;
      position: absolute;
      left: 8px;
      top: 50%;
      transform: translateY(-50%);
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("/dist/images/icons/google-translate.svg");
      pointer-events: none;
    }
  }
}

.goog-te-gadget {
  font-size: 0 !important;

  span {
    display: none;
  }

  .goog-te-combo {
    margin: 0 !important;
    font-family: $body__font-family !important;
    font-size: 2rem !important;
    line-height: 2.4rem !important;
  }
}

.footer-social {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: inline-flex;
    padding: 0 1.2rem;
    border-left: 1px solid rgba($white, .3);
  }

  li:first-child {
    padding-left: 0;
    border-left: none;
  }

  a {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    display: block;
    width: 100%;
    height: auto;
  }
}

.footer-nav {
  border-top: 1px solid rgba($white, .3);
  border-bottom: 1px solid rgba($white, .3);
  font-size: 1.8rem;
  line-height: 2rem;
  @include vertical-padding(7, top);
  @include vertical-padding(7, bottom);
  @include vertical-margin(7, bottom);

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  li {
    margin-bottom: 2rem;
    
    &:last-child {
      margin-bottom: 0;
    }
  }

}

.footer-legal {
  font-size: 1.6rem;
  line-height: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__content {
    display: flex;
    flex-direction: column;
    margin-bottom: .8rem;
  }

}

.footer-contactlink {
  @include vertical-margin(7, right);
  @include vertical-margin(5, bottom);
}

.custom-select {
  height: 36px;
}

.footer-logos {
  display: flex;
  align-items: center;
  @include vertical-margin(3, top);

  a {
    @include vertical-margin(7, right);
    text-decoration: none;

    &:last-child {
      margin-right: 0;
    }
  }

  img {
    display: block;
    max-height: 48px;
    max-width: 130px;
    @include vertical-margin(7, right);

    &:last-child {
      margin-right: 0;
    }
  }
}

.footer-legal__wj {
  @include vertical-margin(3, top);
  display: inline-block;
}

@media screen and (min-width: map-get($breakpoints, sm)) {

  .contact-and-language {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-legal__col {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
  }

  .footer-contactlink {
    margin-bottom: 0;
  }

  .footer-contact-details {
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    line-height: 1;
    align-items: center;
  }
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .footer-legal__content {
    max-width: $container-inner__max-width;
  }

  .footer-nav ul {
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: -.8rem;
    max-width: $container-inner__max-width;

    li {
      margin-right: 1.2rem;
      padding-right: 1.2rem;
      border-right: 1px solid rgba($white, .3);
      margin-bottom: .8rem;

      &:last-child {
        padding-right: 0;
        border-right: none;
        margin-bottom: .8rem;
      }
    }
  }

  .footer-legal__wj {
    text-align: right;
  }

  .footer-logos {
    justify-content: flex-end;
  }

  .footer-contact-details {
    height: 36px;
  }
}

@media screen and (min-width: map-get($breakpoints, lg)) {
  .contact-and-language, .footer-legal {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }

  .contact-and-language .custom-select, .footer-logos {
      margin-top: 0;
  }

  .footer-legal__content {
    margin-bottom: 0;
  }

  .footer-legal__col {
    display: block;
    min-width: 200px;
    text-align: right;
  }
}