.related-entries {
  background-color: $white;
  @include vertical-padding(9, top);
  @include vertical-padding(10, bottom);

  &__heading-and-text {
    @include vertical-margin(9, bottom);
  }

  &__heading {
    @include vertical-margin(7, bottom);
  }
  
  &__link-arrow {
    vertical-align: -1px;
    margin-left: 2px;
  }

  &--warm-grey {
    background-color: $warm-grey-1;
  }
}

.related-entries__heading + .related-entries__link {
  margin-top: map-get($vertical-spacers--mobile, 4) * -1;
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .related-entries__heading + .related-entries__link {
    margin-top: map-get($vertical-spacers--desktop, 4) * -1;
  }
}