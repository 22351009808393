// Some custom styling for making a form assembly embed look decent

.wFormContainer {
  input[type="date"],
  input[type="email"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="url"],
  select,
  textarea {
    display: block;
    margin-bottom: 0;
    width: 100%;

    &[aria-invalid="true"] {
      border-color: $error-red;
      border-width: 3px;
    }
  }

  form {
    display: flex;
    flex-direction: column;
  }

  // Field group
  div[class*="field-container-"],
  .htmlSection {
    @include vertical-margin(7, bottom);
  }

  .wFormTitle {
    @extend h2;
  }

  .section legend {
    @extend h3;
  }

  label.reqMark:after {
    content: "*";
    color: $red;
    margin-left: .3rem;
  }

  .hint {
    display: block;
    color: $blue-steel;
  }

  .hintsBelow {
    .hint,
    .errMsg {
      margin-top: 1.2rem;
    }
  }

  .hintsAbove {
    .hint,
    .errMsg {
      margin-bottom: 1.2rem;
    }
  }

  .errFld {
    padding-left: 2rem;
    border-left: .4rem solid $error-red;
  }

  .errMsg {
    color: $error-red;
    &:before {
      content: "";
      display: inline-block;
      width: 1.6rem;
      height: 1.6rem;
      margin-right: .6rem;
      background-image: url("/dist/images/icons/warning--red.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% auto;
    }
  }

  .choices {
    display: flex;
    flex-direction: column;
  }

  .oneChoice {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: .8rem;
    }

    label {
      margin: 0 0 0 1.2rem;
      font-weight: 400;
    }
  }

  input[type="submit"],
  .wfPageNextButton {
    @include button-focus();
    @include button($btn__text-colour,$btn__text-colour--hover, $btn__background-colour, $btn__shadow-colour, $btn__background-colour--hover, $btn__shadow-colour--hover, $btn__background-colour--active);
  }

  .wfPagePreviousButton {
    @include button-focus();
    @include button($btn__text-colour: $white,$btn__text-colour--hover: $white, $btn__background-colour: $blue-steel, $btn__shadow-colour: #384753, $btn__background-colour--hover: #384753, $btn__shadow-colour--hover: #13191D, $btn__background-colour--active: $black);
  }

  // Pagination
  .wfPage {
    display: none;
  }

  .actions,
  .wfPagingButtons {
    margin-top: (map-get($vertical-spacers--mobile, 8) * -1);
  }

  // If the actions container follows a page that is not active, then don't show it
  .wfPage + .actions {
    display: none;
  }

  // Hidden field class
  .wf-acl-hidden,
  .offstate {
    display: none;
  }

  // Autocomplete dropdown
  .twitter-typeahead {
    display: block !important; // NOTE: this makes sure that the autocomplete box is full width
  }
  .tt-menu {
    background-color: white;
    outline: 3px solid $green-focus;
    transform: translateY(4px);
    width: 100%;

    .tt-suggestion.tt-selectable,
    .tt-empty-message {
      padding: 0.2rem 0.6rem;
    }
    .tt-suggestion.tt-selectable {
      cursor: pointer;
      border-bottom: 2px solid $warm-grey-1;

      &:last-of-type {
        border-bottom: 0 none;
      }

      &:hover {
        background-color: $warm-grey-1;
      }
    }
    .tt-empty-message {
      color: #666;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .wFormContainer {
    .errFld {
      padding-left: 2.4rem;
    }
  }

  .actions,
  .wfPagingButtons {
    margin-top: (map-get($vertical-spacers--desktop, 8) * -1);
  }
}