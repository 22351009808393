.pagination {
  color: $blue-steel;
  @include vertical-padding(9, top);

  &__heading {
    @include vertical-margin(4, bottom);
  }

  &__numbers {
    @include list-reset();
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -.8rem;

    li {
      margin-right: .8rem;
      margin-bottom: .8rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    border: 2px solid $primary-colour;

    &:hover {
      border-color: $green-access-dark-10;
      text-decoration: none;
    }

    &:active, 
    &:focus {
      border: none;
    }

    &__current {
      color: $white;
      background-color: $primary-colour;

      &:hover {
        border-color: $primary-colour;
      }
    }

    &.disabled {
      color: $blue-steel-tint-50;
      border-color: $blue-steel-tint-50;
      cursor: not-allowed;

      &:active, 
      &:focus {
        border: 2px solid $blue-steel-tint-50;
      }
    }
  }
}