.results-variation {
  .field-row {
    margin-bottom: 10px;
  }

  .variation-results-card .radio-group label {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    color: #000;
    font-size: 2.2rem;
    line-height: 3.2rem;
  }

  .postcode-inputs--inline .input--invert:not(.exception) {
    color: $black;

    &::placeholder {
      color: $black;
    }
  }

  .site-content__body {
    background-color: $warm-grey-1;
  }

  .loading-spinner {
    border: 12px solid $warm-grey-2;
    border-top: 12px solid $green-access;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 0.6s linear infinite;
    margin: 0 auto;
    margin-top: 100px;
  }

  @keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
  }

  .variation-filters {
    display: flex;
    gap: 10px;
    flex-flow: wrap;
    margin-bottom: 20px;
  }

  .variation-filter {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    border: 2px solid $green-access;
    padding: 8px 10px;
    cursor: pointer;
    background: white;

    p {
      margin: 0;
      color: $blue-mid;
      font-weight: 700;
      font-size: 22px;
      line-height: 26px;
      pointer-events: none;
    }

    span {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      background-color: $blue-steel;
      font-size: 16px;
      color: $white;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
    }

    &:hover {
      p {
        color: $blue-mid-dark-20;
      }
    }

    &.variation-filter--active {
      background-color: $green-access;

      p {
        color: $white;
      }
    }

    &.variation-filter--disabled {
      cursor: default;
      pointer-events: none;
      border-color: $warm-grey-3;
      background: transparent;

      p {
        color: $warm-grey-3;
      }

      span {
        background-color: $warm-grey-3;
      }

      svg circle {
        fill: $warm-grey-3;
      }
    }
  }

  .variation-filter__tooltip-icon {
    position: relative;
    display: flex;
    flex-shrink: 0;

    &:hover {
      .variation-filter__tooltip {
        display: block;

        &:before {
          content: "";
          display: block;
          width: 16px;
          height: 16px;
          background-color: $white;
          border-left: 2px solid $green-access;
          border-top: 2px solid $green-access;
          position: absolute;
          top: -10px;
          left: 12px;
          transform: rotate(45deg);
        }
      }
    }
  }

  .variation-filter__tooltip {
    display: none;
    position: absolute;
    background: white;
    border: 2px solid $green-access;
    top: 36px;
    width: 288px;
    padding: 10px 10px 10px 12px;
    left: -12px;
    font-size: 18px;
    line-height: 22px;
    z-index: 100;
  }

  .results-amount {
    margin: 40px 0;
  }

  .variation-pagination-button:last-child {
    margin-bottom: 0;
  }

  .variation-results-container {
    .variation-results-card {
      margin-bottom: 50px;
    }
  }

  .variation-results-card {
    background:$white;
    width: 100%;
    max-width: 780px;
    padding: 16px 22px 24px;
    border: 2px solid $green-access;
    transition: border-color 0.3s ease;
    // margin-bottom: 50px;
    // cursor: pointer;

    &.variation-results-card--hidden {
      display: none;
    }

    // &:hover {
    //   border-color: $green-access-dark-10;

    //   .card-title {
    //     text-decoration: underline;
    //   }

    //   .card-button {
    //     color: $white !important;
    //     cursor: pointer;
    //     text-decoration: none;
    //     background-color: $green-access-dark-10;
    //     box-shadow: 0 4px $green-access-dark-20;
    //   }
    // }

    label {
      color: $blue-steel;
      font-size: 15px;
      line-height: 24px;
      margin-bottom: 6px;
    }

    .card-title {
      margin-bottom: 20px;
      line-height: 3.2rem;

      &:first-child {
        margin-top: 8px;
      }
    }

    p {
      margin: 0;
    }

    .address {
      color: $black;
      font-weight: 700;
      font-size: 22px;
      line-height: 32px;
    }

    .distance {
      color: $black;
      font-size: 18px;
      line-height: 24px;
    }

    button,
    .button,
    .modal a.button:not(.button__join-course) {
      margin-top: 20px;
      margin-bottom: 0;
    }

    .button__join-course {
      margin-bottom: 24px;
    }

    button svg {
      margin-left: 10px;
    }

    .modal {
      display: none;
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      z-index: 1000;
      overflow: auto;
      background-color: rgba(0, 0, 0, 0.8);
    }

    .modal__content {
      position: relative;
      background-color: #fff;
      margin: 20px;
      padding: 50px 20px 20px 20px;
      width: 90%;
      max-height: 90%;
      overflow: auto;

      hr {
        border: none;
        border-top: 2px solid $warm-grey-2;
        margin: 16px 0;
      }
    }

    .modal__additional-info {
      h3 {
        margin-top: 24px;
        margin-bottom: 8px;
      }

      .centre-contact__email svg {
        margin-top: 2px;
      }

      .centre-contact__details {
        margin-top: 0;
      }
    }

    .modal__content .maps-link {
      margin-top: 10px;
      margin-bottom: 10px;
      display: block;
    }

    .modal__close {
      cursor: pointer;
      position: absolute;
      top: 16px;
      right: 20px;
      display: flex;
      align-items: center;
      gap: 10px;
      color: $blue-mid;

      &:hover {
        color: $blue-mid-dark-20;
      }
    }
  }

  @media screen and (min-width: map-get($breakpoints, sm)) {
    .variation-results-card {
      .modal__content {
        padding: 20px;

        h2 {
          margin-right: 80px;
        }
      }
    }
  }

  @media screen and (min-width: map-get($breakpoints, md)) {
    .variation-results-card {
      padding: 18px 26px 26px;

      .card-title {
        line-height: 3.8rem;
      }
    }
  }

  @media screen and (min-width: map-get($breakpoints, lg)) {
    .variation-results-card {
      .modal__content {
        width: 640px;
      }
    }
  }
}